import { useQuery } from "react-query";
import { FilterUnit } from "../../../@types/entities/product.types";
import {
  getLookbookDetails,
  getLookbooks,
  getUserFeed,
} from "../../../services/api/lookbook/lookbook.api";
import {
  getLookalikeFilterOptions,
  getLookalikeProducts,
} from "../../../services/api/products/lookalike/lookalike.api";

export const useLookbookDetails = (id: string) => {
  return useQuery({
    queryKey: ["lookbook", id],
    queryFn: async () => {
      const res = await getLookbookDetails(id);
      return res;
    },
    enabled: !!id,
  });
};

export const useLookbooks = () => {
  return useQuery({
    queryKey: ["lookbooks"],
    queryFn: async () => {
      const res = await getLookbooks();
      return res;
    },
  });
};
export const useLookbooksFeed = () => {
  return useQuery({
    queryKey: ["lookbooksFeed"],
    queryFn: async () => {
      const res = await getUserFeed();
      return res;
    },
  });
};

export const useLookalikeProducts = (id?: string, filters?: FilterUnit[]) => {
  return useQuery({
    queryKey: ["lookalike", id, filters],
    enabled: !!id,
    queryFn: async () => {
      const res = await getLookalikeProducts(id!, filters);
      return res;
    },
  });
};

export const PREVIEW_LOOKALIKE_FILTERS = [
  "main_color_specific",
  "sleeve_length",
  "length_group",
  "strap_group",
  "neckline_group",
  "pattern_style",
  "skirt_group",
];
export const useLookalikeFilterOptions = (type: string) => {
  return useQuery({
    queryKey: ["lookalike", type],
    queryFn: async () => {
      const res: { filters: FilterUnit[] } = await getLookalikeFilterOptions(
        type
      );
      // TODO:THIS IS BACKEND
      const additionalFilters: FilterUnit[] = [
        { key: "price", value: [15, 5000] as any },
      ];
      return [
        ...additionalFilters,
        ...res.filters.filter((f) => PREVIEW_LOOKALIKE_FILTERS.includes(f.key)),
      ];
    },
  });
};
