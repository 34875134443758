import { ChatMsg } from "../../../@types/entities/chat";
import { store } from "../../../store";

export type OpenAIChatMsg =
  | { role: "user" | "system" | "assistant"; content: string }
  | ChatMsg["func"];

const formatMsg = (input: string): OpenAIChatMsg => ({
  role: "user",
  content: input,
});

export const convertToGPTChat = (chat: ChatMsg): any => {
  if (chat.func) return chat.func;
  return {
    content:
      chat.sender === "chat"
        ? JSON.stringify({
            content: chat.content,
            options: chat.data?.options,
            parsing: chat.data?.parsing,
          })
        : chat.content,
    role: chat.sender === "chat" ? "assistant" : "user",
  };
};

const getAIContextByUser = (): OpenAIChatMsg => {
  const user = store.getState().app.user;
  const msg: OpenAIChatMsg = {
    role: "system",
    content:
      "You are a fashion personal shopping assistant. you understand fashion at a very high level and you know the subtleties of fashion and context. you are helping the following user. speak to her profile. the user is - ",
  };
  if (user?.tag === "avatarMaya") {
    return {
      ...msg,
      content: `${msg.content} maya. maya lives in Tel aviv and is a yoga teacher. she likes casual and athletic wears but is trendy as well. she usually buys fast fashion but in style. her favorite brands are Zara, Asos, urban outfitters, All Saints. she likes to follow the style of Hailey Bieber and Kendell Jenner. she is blonde, 1.58M tall and likes outfits that compliment her butt and her collarbone.`,
    };
  }
  return {
    ...msg,
    content: `${msg.content} Vicky. Vicky is an important partner in a law firm in new york. she usually wears formal clothes for meetings and needs to wear impressive outfits that convey strength yet femininity. she likes outfits that compliment her long legs and blonde hair. she is curvey and likes to wear tighter clothes to emphasize her curves. her favorite department stores are Saks Fifth Avenue and Nordstrom. she Likes alexander mcqueen, Norma kamali, Oscar de larente. her favorite celeb which she likes her style is Amal Clooney.`,
  };
};
