import QueryString from "qs";
import {
  FilterUnit,
  ProductDetails,
} from "../../../@types/entities/product.types";
import { FeedPage } from "../../../@types/feed";
import { BasicQuery, InfiniteQuery } from "../../../@types/query";
import { api, baseUrl } from "../api";

type Filters = {
  // TODO: THIS IS THE FILTERS FOR BOTH THE SEARCH AND THE STANDARD FILTERS
  query?: string;
  mainColor?: string[];
  brand?: string[];
  fit?: string[];
};

export const getProducts = async ({
  limit = 50,
  skip = 0,
  ...rest
}: BasicQuery): Promise<{
  items: ProductDetails[];
  features: FilterUnit[];
}> => {
  const query = QueryString.stringify({ ...rest, limit, skip });

  try {
    const res = await api.calls.get(
      `${baseUrl}/api/v2/search/products?${query}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [], features: [] };
  }
};
export const getSimilarProducts = async (
  productId: string,
  query: BasicQuery
): Promise<{ items: ProductDetails[] }> => {
  try {
    const qs = QueryString.stringify(query);
    const res = await api.calls.get(
      `${baseUrl}/api/products/${productId}/similar/items?${qs}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};
export const getSameFashionFor = async (
  productId: string
): Promise<{ items: ProductDetails[] }> => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/${productId}/similar/fashion`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};

export const getProductFilters = async (): Promise<{
  items: {
    features: { filters: FilterUnit[] };
    metadata: { filters: FilterUnit[] };
  };
}> => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/products/filters`);
    return res.data;
  } catch (error) {
    return { items: { features: { filters: [] }, metadata: { filters: [] } } };
  }
};

export const getProductCount = async (filters: FilterUnit[]) => {
  console.log(filters);

  try {
    const query = QueryString.stringify({ filters });
    console.log(query);

    const res = await api.calls.get(`${baseUrl}/api/products/count?${query}`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getProductSections = async (): Promise<{
  items: FeedPage;
}> => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/products/sections?limit=6`);
    return res.data;
  } catch (error) {
    console.error(error);
    return {
      items: {
        userProducts: [],
        recentProducts: [],
        brands: [],
        recentListSuggestions: { suggestions: [], list: "" },
      },
    };
  }
};

export const dislikeProduct = async (productId: string) => {
  try {
    const res = await api.calls.post(
      `${baseUrl}/api/products/dislike/${productId}`
    );
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getSuggestedProductsForUser = async (
  weights: Record<string, number>,
  query: InfiniteQuery
) => {
  const qs = QueryString.stringify({ weights, ...query });
  try {
    const res = await api.calls.get(`${baseUrl}/api/v2/feed?${qs}`);
    return res.data;
  } catch (error) {
    return { items: [] };
  }
};

export const getRecentlyViewedProducts = async (query: InfiniteQuery) => {
  const qs = QueryString.stringify(query);
  try {
    const res = await api.calls.get(`${baseUrl}/api/products/recent?${qs}`);
    return res.data;
  } catch (error) {
    return { items: [] };
  }
};
