import { ReactNode } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import appStyles from "../../App.module.scss";
interface CarouselProps {
  items: Item[];
  settings?: { perView?: number; enablePagination?: boolean };
  onClickItem?: (id: string) => void;
}

interface Item {
  element: ReactNode;
  id?: string;
  title?: string;
  description?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  items,
  settings = {},
  onClickItem,
}) => {
  return (
    <div>
      <Swiper
        slidesPerView={settings.perView ?? 1}
        className={appStyles.swiper}
        modules={[Pagination]}
        pagination={{ enabled: settings.enablePagination }}
      >
        {items.map((item) => (
          <SwiperSlide
            style={{
              flexDirection: "column",
              display: "flex",
              // alignItems: "center",
            }}
            onClick={() => onClickItem && onClickItem(item.id ?? "NO ID")}
          >
            <div>
              {item.element}
              <div
                style={{
                  margin: settings.enablePagination
                    ? "16px 16px 24px 16px "
                    : "16px 16px 10px 16px",
                }}
                className="column alignCenter gapLg"
              >
                {(item.title || item.description) && (
                  <div className="column gapLg">
                    {item.title && (
                      <h3 style={{ textAlign: "center" }}>{item.title}</h3>
                    )}
                    {item.description && (
                      <p className={"subTitle center"}>{item.description}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
