import { api, baseUrl } from "../api";
import { ChatMessagePayload } from "../chat/chat";

export const getStyles = async (form: any) => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/v2/onboard/styles`, {
      params: form,
    });
    return res.data.items;
  } catch (error) {
    console.error("Error fetching styles:", error);
    throw error;
  }
};

export const getSuggestedBrands = async (form: any) => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/v2/onboard/brands/suggested`,
      {
        params: form,
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching suggested brands:", error);
    throw error;
  }
};

export const postChatMsg = async (msg: string) => {
  try {
    const res = await api.calls.post(`${baseUrl}/api/v2/onboard/chat/message`, {
      message: msg,
    });
    return res.data;
  } catch (error) {
    console.error("Error posting chat message:", error);
    throw error;
  }
};

export const postChatHistory = async (payload: ChatMessagePayload) => {
  try {
    // await wait(1000);
    // return {
    //   content: "",
    //   data: {
    //     products: [
    //       {
    //         imgUrls: [
    //           "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1314229-CAF.json?_s=RAABAB0%20600w",
    //         ],
    //         title: "test",
    //         brand: "test",
    //         price: 99.0,
    //       },
    //       {
    //         imgUrls: [
    //           "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1314229-CAF.json?_s=RAABAB0%20600w",
    //         ],
    //         title: "test",
    //         brand: "test",
    //         price: 99.0,
    //       },
    //       {
    //         imgUrls: [
    //           "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1314229-CAF.json?_s=RAABAB0%20600w",
    //         ],
    //         title: "test",
    //         brand: "test",
    //         price: 99.0,
    //       },
    //       {
    //         imgUrls: [
    //           "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1314229-CAF.json?_s=RAABAB0%20600w",
    //         ],
    //         title: "test",
    //         brand: "test",
    //         price: 99.0,
    //       },
    //     ],
    //   },
    // };

    const res = await api.calls.post(`${baseUrl}/api/v2/onboard/msg`, {
      history: payload.history,
      content: payload.content,
    });
    return res.data;
  } catch (error) {
    console.error("Error posting chat history:", error);
    throw error;
  }
};

export const onboardingApi = {
  getStyles,
  getSuggestedBrands,
  postChatMsg,
  postChatHistory,
};
