import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Back } from "../../components/back/Back";
import { Button } from "../../components/button/Button";
import { usePageStyles } from "../../hooks/ui/usePageStyles";
import { login } from "../../services/api/users/users.api";
import { appActions } from "../../store/app/slice";
import { UserSignIn } from "../onboarding/OnboardPage";
import s from "./Login.module.scss";

export const Login = () => {
  const [form, setForm] = useState({ username: "", password: "" });
  const styles = usePageStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogin = async () => {
    try {
      const data = await login(form);
      dispatch(appActions.onSelectUser(data.user));
      navigate(`/home?${searchParams.toString()}`);
    } catch (error) {
      setError("username and password do not match");
    }
  };
  return (
    <div style={styles} className={s.page}>
      <Link
        to="/"
        style={{
          alignSelf: "flex-start",
          padding: "10px",
          marginInlineStart: "16px",
          borderRadius: "50%",
          marginBottom: "32px",
          border: "thin solid rgba(0,0,0,0.1)",
        }}
      >
        <Back />
      </Link>
      <div className="flex1 paddingMd">
        <UserSignIn
          isLogin
          error={error}
          form={form}
          onChange={(form) => setForm({ ...form, username: form.username })}
        />
      </div>

      <Button
        disabled={!form.password || !form.username}
        onClick={onLogin}
        className={s.ctaBtn}
      >
        Sign In
      </Button>
    </div>
  );
};
