import { pick } from "lodash";
import { useContext } from "react";
import { ProductDetails } from "../@types/entities/product.types";
import { AppContext } from "../App";
import {
  convertToFeatureSentence,
  getKeysForSimilarProduct,
} from "../services/api/chat/chat";
import { socketService } from "../services/mock/socket";
import { useAppSelector } from "../store";

export const useChat = () => {
  const { searchTerm, user } = useAppSelector(({ app }) => app);
  const { onChat } = useContext(AppContext);

  // const refineResults = () => {
  //   onChat();
  //   socketService.emit("userMessage", {
  //     data: {
  //       sender: "user",
  //       content: "fine-tune results for the search term " + searchTerm,
  //       timestamp: Date.now(),
  //     },
  //     type: "fineTuneResult",
  //   });
  // };

  const onChatWithEngine = (userMsg?: string) => {
    onChat();
    // if(!user)
    // socketService.emit("chatMessage", "app", {
    //   sender: "chat",
    //   content: "How can i help you",
    //   timestamp: Date.now(),
    // });
    if (userMsg) {
      console.log("user message", userMsg);

      socketService.emit("userMessage", "app", {
        data: {
          sender: "user",
          content: userMsg,
          timestamp: Date.now(),
        },
        type: "gpt",
      });
    }
  };

  const queryOnProduct = (product: ProductDetails) => {
    // onChat();
    console.log(
      "Emitting on product",
      { product },
      convertToFeatureSentence(pick(product, ...getKeysForSimilarProduct()))
    );

    socketService.emit("userMessage", product._id!, {
      // TODO: GOD FORGIVE ME FOR THIS CODE.
      data: {
        sender: "user",
        content: `<PRODUCT_REFERENCE>
        I want to refine this product.
        })} <PRODUCT_REFERENCE/> `,
        data: {
          products: [product],
          productReferenceId: product._id,
        },
        timestamp: Date.now(),
      },
      type: "gpt",
    });
  };

  return { onChatWithEngine, queryOnProduct, onChat };
};
