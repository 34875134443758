import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/button/Button";
import { useDelayedFunc } from "../../../../hooks/ui/useDelayedClose";
import { ChatTitle } from "../../common/ChatTitle/ChatTitle";
import s from "./baseStep.module.scss";
type BaseStepProps = {
  title: string; // The question or title of the step
  Component: React.FC<{ onChange: (data: any) => void; data: any }>;
  validate: (data: any) => boolean;
  onSubmit: (data: any) => void;
  onSkip?: () => void;
  initialData: any;
  error?: any;
  awaitResponse?: boolean;
};

const BaseOnboardingStep: React.FC<BaseStepProps> = ({
  title,
  Component,
  validate,
  onSubmit,
  onSkip,
  initialData,
  error,
  awaitResponse,
}) => {
  const [data, setData] = useState<any>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isClosing, close] = useDelayedFunc(600);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  }, []);

  const handleSkip = () => {
    if (onSkip) {
      onSkip();
    }
  };

  const handleNext = async () => {
    if (awaitResponse) {
      try {
        setIsLoading(true);
        await onSubmit(data);
        setIsLoading(false);
      } catch (error) {}
    } else close(() => onSubmit(data));
  };

  return (
    <div className={`${s.container} `}>
      <div
        className={`${s.stepContent} ${isClosing && s.close} ${
          isOpen && s.open
        }`}
      >
        <ChatTitle title={title} />
        <div className="flex1 column scrollAuto">
          <Component
            onChange={(newData: any) => {
              // setError(null);
              setData(newData);
            }}
            data={data}
          />
        </div>
      </div>
      <div className="column gapSm">
        {error && <p className={s.error}>Error: {error}</p>}
        <Button
          onClick={handleNext}
          disabled={!validate(data)}
          className={s.ctaBtn}
          isLoading={isLoading}
        >
          {"Continue"}
        </Button>
        {onSkip && <button onClick={handleSkip}>Skip</button>}
      </div>
    </div>
  );
};

export default BaseOnboardingStep;
