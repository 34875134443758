import { get } from "lodash";
import { Pill } from "../../../../components/pill/Pill";
import { toggleInList } from "../../../../utils/array";
import s from "./BodyInfo.module.scss";
const values = [
  {
    title: "Body type",
    values: [
      "athletic",
      "curvey",
      "hourglass",
      "pear",
      "apple",
      "inverted triangle",
      "petite",
      "tall",
      "plus size",
    ],
    key: "bodyType",
  },
  {
    title: "parts to highlight",
    values: ["bust", "waist", "legs", "arms", "back", "neck"],
    key: "highlight",
  },
  {
    title: "parts to downplay",
    values: ["bust", "waist", "legs", "arms", "back", "neck"],
    key: "downplay",
  },
];

export const BodyInfo: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ data, onChange }) => {
  const onChangeField = (key: string, value: string) => {
    onChange({ ...data, [key]: toggleInList(get(data, key, []), value) });
  };

  return (
    <div className="column gapXlg">
      {values.map((v) => (
        <div className="gapXlg column">
          <h4>{v.title}</h4>
          <div className={s.valueContainer}>
            {v.values.map((p) => (
              <Pill
                size="sm"
                isSelected={get(data, v.key, []).includes(p)}
                onClick={() => onChangeField(v.key, p)}
              >
                {p}
              </Pill>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
