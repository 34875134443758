import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useInfiniteMessageProductsResults } from "../../../../hooks/query/chat/useChatProducts";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";

export const ChatResultsPage: React.FC<{
  msgId: string | null;
  onClose: () => void;
}> = ({ msgId, onClose }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteMessageProductsResults(msgId);
  const products = useFlattenInfiniteQuery(data);

  return (
    <SlidingWindow
      title="Chat Results"
      direction="right"
      isOpen={!!msgId}
      onClose={onClose}
    >
      {msgId ? (
        <div className="paddingMd">
          <ProductsGrid
            products={products ?? []}
            hasNextPage={!!hasNextPage}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      ) : (
        <></>
      )}
    </SlidingWindow>
  );
};
