import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { ShoppingPreferencesInfo } from "./ShoppingPreferencesInfo";

export const ShoppingPreferencesStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={ShoppingPreferencesInfo}
      validate={() => true}
      onSubmit={(data) => props.onNextStep({ pricing: data })}
      onSkip={props.onSkip}
      initialData={props.preloadedData.pricing}
      title="What are your shopping preferences?"
    />
  );
};
