import { cloneDeep } from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { Pill } from "../../../../components/pill/Pill";
import {
  ProductSkeleton,
  StyledSkeleton,
} from "../../../../components/skeleton/ProductSkeleton";
import {
  Transition,
  useTransitionAnimation,
} from "../../../../hooks/useTransitionAnimation";
import { onboardingApi } from "../../../../services/api/onboard/onboard.api";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import { ChatTitle } from "../../common/ChatTitle/ChatTitle";
import { OnboardingStep } from "../../types";
import s from "../baseStep/baseStep.module.scss";
import styles from "./introFlow.module.scss";

export const IntroFlow: React.FC<OnboardingStep> = ({
  onNextStep,
  onSkip,
  preloadedData,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const MAX_STEPS = 2;
  return (
    <>
      <DynamicFlow
        onComplete={() => {
          if (currentStep < MAX_STEPS - 1) {
            setCurrentStep(currentStep + 1);
          } else {
            onNextStep(true);
          }
        }}
        step={currentStep}
      />
    </>
  );
};

type ChatMessage = any;
const ChatFlow = ({ onComplete }: { onComplete: () => void }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const { startExit } = useTransitionAnimation({
    enterDelay: 200,
    exitDelay: 0,
    onExitComplete: (cb) => cb(),
  });

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, isLoading]);

  const handleUserMessage = async (message: string) => {
    const userMessage = { content: message, role: "user" } as any;
    const history = cloneDeep(messages);
    setMessages((prev) => [...prev, userMessage]);
    setIsLoading(true);
    try {
      const response = await onboardingApi.postChatHistory({
        history: history,
        content: message,
      });

      setMessages((prev) => [
        ...prev,
        {
          data: { products: response.products },
          content: response.content ?? "",
          role: "assistant",
        } as any,
      ]);
    } catch (error) {
      console.error("Error in chat flow:", error);
    } finally {
      setIsLoading(false);
      setCurrentStep((s) => s + 1);
    }
  };

  const renderStep = () => {
    const commonProps = {
      messages,
      isLoading,
      scrollRef,
    };

    switch (currentStep) {
      case 0:
        return (
          <ChatStepLayout
            {...commonProps}
            title="Select one of the options below to get started"
            options={[
              {
                text: "Fun dress for a beach party",
                onClick: () => handleUserMessage("Fun dress for a beach party"),
              },
              {
                text: "Marilyn Monroe inspired dress",
                onClick: () =>
                  handleUserMessage(
                    "Marilyn Monroe inspired dress for a cocktail party"
                  ),
              },
              {
                text: "flowy metalic dress with chic boho vibes",
                onClick: () =>
                  handleUserMessage(
                    "flowy metalic dress for a formal event giving chic boho vibes"
                  ),
              },
            ]}
          />
        );
      case 1:
        return (
          <ChatStepLayout
            {...commonProps}
            title="How would you like to refine your choice?"
            options={[
              {
                text: "Make it more casual",
                onClick: () => handleUserMessage("Make it more casual"),
              },
              {
                text: "Show me something more elegant",
                onClick: () =>
                  handleUserMessage("Show me something more elegant"),
              },
              {
                text: "I want brighter colors and a shorter length",
                onClick: () =>
                  handleUserMessage(
                    "I want brighter colors and a shorter length"
                  ),
              },
            ]}
          />
        );
      case 2:
        return (
          <ChatStepLayout
            {...commonProps}
            title="Shopping just got a lot easier! let's create your profile"
            options={[
              {
                text: "Nice! create my profile",
                onClick: () => startExit(() => onComplete()),
              },
            ]}
          />
        );
      default:
        return null;
    }
  };

  return renderStep();
};

const ProductsMessage = ({
  products,
  isLoading,
}: {
  products?: Array<{
    imgUrls: string[];
    title: string;
    brand: string;
    price: number;
  }>;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="gapMd flex1 paddingMd">
        <div className="column gapSm flex1">
          <div className="gapMd flex">
            <Gigi width="24px" height="24px" />
            <div className="column gapSm flex1">
              <StyledSkeleton
                animation="wave"
                style={{ width: "80%", backgroundColor: "#e4e4e4" }}
              />
              <StyledSkeleton
                variant="text"
                animation="wave"
                style={{ width: "50%", backgroundColor: "#e4e4e4" }}
              />
            </div>
          </div>
          <div className="gapMd flex">
            <ProductSkeleton repeat={3} sumVersion />
          </div>
        </div>
      </div>
    );
  }

  if (!products?.length) return null;

  return (
    <div className={styles.chatSenderContainer}>
      <div className={styles.productsMsgContainer}>
        <div className={styles.productsContainer}>
          <div
            style={{
              overflowX: "auto",
              display: "flex",
              gap: "15px",
              alignItems: "center",
              overflowY: "hidden",
            }}
          >
            {products.map((product, index) => (
              <div
                key={index}
                style={{ animationDelay: `${index * 0.05}s` }}
                className={styles.chatProductContainer}
              >
                <ProductCard
                  className={styles.chatProduct}
                  size="sm"
                  product={product as MiniProduct}
                  disablePreviewSwipe
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatStepLayout = ({
  title,
  messages,
  options,
  isLoading,
  scrollRef,
}: {
  title: string;
  messages: ChatMessage[];
  options: Array<{ text: string; onClick: () => void }>;
  isLoading: boolean;
  scrollRef: RefObject<HTMLDivElement>;
}) => {
  const { startExit, ...rest } = useTransitionAnimation({
    enterDelay: 200,
    exitDelay: 500,
    onExitComplete: (cb) => cb(),
  });
  const [hideOptions, setHideOptions] = useState(false);

  useEffect(() => {
    setHideOptions(false);
  }, [JSON.stringify(options)]);

  return (
    <div className={`${s.container} ${styles.chatLayoutContainer}`}>
      <div>
        <Transition {...rest}>
          <ChatTitle title={title} />
        </Transition>
      </div>

      <div className={styles.scrollableContent} ref={scrollRef}>
        <div className="column gapMd">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`${styles.msgContainer} ${
                msg.role === "user" ? styles.user : ""
              }`}
            >
              {msg.content && msg.role === "user" && (
                <div className={styles.userMsg}>{msg.content}</div>
              )}
              {msg.data?.products && (
                <ProductsMessage
                  products={msg.data.products}
                  isLoading={false}
                />
              )}
            </div>
          ))}
          {isLoading && <ProductsMessage isLoading={true} />}
        </div>
      </div>

      <div className={styles.floatingPills}>
        <Transition {...rest}>
          <div className={`column alignCenter gapMd`}>
            {!hideOptions &&
              options.map((option) => (
                <Pill
                  isSelected
                  size="lg"
                  key={option.text}
                  onClick={() => {
                    setHideOptions(true);
                    option.onClick();
                  }}
                >
                  {option.text}
                </Pill>
              ))}
          </div>
        </Transition>
      </div>
    </div>
  );
};

const HelloFlow = ({ onComplete }: { onComplete: () => void }) => {
  const { startExit, ...rest } = useTransitionAnimation({
    enterDelay: 200,
    exitDelay: 500,
    onExitComplete: (cb) => {
      cb();
    },
  });
  return (
    <div className={`${s.container} spaceBetween`}>
      <Transition {...rest}>
        <div
          style={{ marginTop: "50%", minHeight: "200px" }}
          className=" flex1 column"
        >
          <ChatTitle
            title={
              "Hi there! I'm Gigi, your personal fashion shopper.\n With me, you can save time and money, and enhance your shopping experience"
            }
          />
        </div>
      </Transition>
      <div style={{ paddingBottom: "42px" }} className={"column alignCenter"}>
        <Pill
          size="lg"
          isSelected
          onClick={() => startExit(() => onComplete())}
        >
          Show me how it works
        </Pill>
      </div>
    </div>
  );
};

const DynamicFlow = ({
  onComplete,
  step,
}: {
  onComplete: () => void;
  step: number;
}) => {
  switch (step) {
    case 0:
      return <HelloFlow key={step} onComplete={onComplete} />;
    case 1:
      return <ChatFlow key={step} onComplete={onComplete} />;
    default:
      return null;
  }
};
