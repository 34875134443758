import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { ProfessionInfo } from "./ProfessionInfo";

export const ProfessionStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={ProfessionInfo}
      title="What is your occupation?"
      onSubmit={(data) => props.onNextStep({ profession: data })}
      onSkip={props.onSkip}
      initialData={props.preloadedData.profession ?? {}}
      validate={(data: any) => data.dressCode && data.occupation}
    />
  );
};
