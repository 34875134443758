import { keyBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  FilterUnit,
  ProductDetails,
} from "../../@types/entities/product.types";
import { FiltersPreview } from "../../components/filters/filtersPreview/FiltersPreview";
import {
  PREVIEW_LOOKALIKE_FILTERS,
  useLookalikeFilterOptions,
  useLookalikeProducts,
} from "../../hooks/query/lookbook/useLookbook";
import { useFilterActions } from "../../hooks/system/useFilterActions";
import { BaseLookalikeProducts } from "./LookalikeProducts";

export const LookalikePage = () => {
  const { parseId } = useParams();
  return <LookalikeProducts id={parseId} />;
};

export const LookalikeProducts: React.FC<{ id?: string }> = ({ id }) => {
  const isMount = useRef(false);
  const [filters, setFilters] = useState<FilterUnit[]>();
  const { data, isLoading } = useLookalikeProducts(id, filters);
  const { data: filterOptions } = useLookalikeFilterOptions("dress");
  const { updateFilter } = useFilterActions();
  const consistentPathLookalike = useRef<string>();

  useEffect(() => {
    if (data?.lookalike && filterOptions && !isMount.current) {
      consistentPathLookalike.current = data?.lookalike.path;
      setFilters(
        convertLookalikeToActiveFilters(filterOptions, data.lookalike)
      );
      isMount.current = true;
    }
  }, [data?.lookalike, filterOptions]);

  console.log(filterOptions);

  return (
    <BaseLookalikeProducts
      products={data?.items ?? []}
      isLoading={isLoading}
      lookalikeImg={consistentPathLookalike.current}
      filters={
        filterOptions &&
        filters && (
          <FiltersPreview
            options={adaptFilterOptions(filterOptions)}
            activeFilters={keyBy(filters, "key")}
            onChange={(key, value) =>
              setFilters((prev) => updateFilter(key, value, prev))
            }
            previewFilters={PREVIEW_LOOKALIKE_FILTERS}
          />
        )
      }
      onClickProduct={(p) => {
        window.open(p.vendors[0].url, "_blank");
      }}
    />
  );
};

const labelKeyMap = {
  length_group: "length",
  skirt_group: "skirt",
  strap_group: "strap",
  neckline_group: "neckline",
  sleeve_length: "sleeve",
  pattern_style: "pattern",
  main_color_specific: "color",
};

export const adaptFilterOptions = (filterOptions: FilterUnit[]) => {
  return filterOptions.map((f) => {
    let customValue = undefined;
    if (f.key === "price") {
      return {
        ...f,
        label: labelKeyMap[f.key as keyof typeof labelKeyMap] ?? f.key,
        customValuePreview: (value: any) => {
          if (value) {
            return `$${value.gt} - $${value.lt}`;
          }
          return "";
        },
      };
    }
    return {
      ...f,
      label: labelKeyMap[f.key as keyof typeof labelKeyMap] ?? f.key,
    };
  });
};

const convertLookalikeToActiveFilters = (
  filterOptions: FilterUnit[],
  lookalike: any
): FilterUnit[] => {
  return filterOptions.map((f) => {
    if (f.key === "price") {
      return {
        key: f.key,
        value: null,
      };
    }
    return {
      key: f.key,
      value: lookalike[f.key as keyof ProductDetails]
        ? [lookalike[f.key as keyof ProductDetails]]
        : [],
    };
  }) as FilterUnit[];
};
