import { Image } from "../image/Img";
import s from "./ImageCollage.module.scss";

export const ImageCollage: React.FC<{
  imgs: { src: string; fallback?: string }[];
  count?: number;
}> = ({ imgs, count = 6 }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        // gap: "4px",
        // width: "100%",
        // height: "100%",
      }}
      className={s.collection}
    >
      {imgs.slice(0, count).map((i) => (
        <div
          className=" flex flex1"
          style={{
            aspectRatio: "2/3",
            // width: "100%",
          }}
        >
          <Image
            fallback={i.fallback ?? ""}
            src={i.src}
            alt="image"
            style={{
              minWidth: "120px",
              flex: 1,
              // objectFit: "contain",
            }}
          />
        </div>
      ))}
    </div>
  );
};
