import { BrandsList } from "../../../components/filters/filterCategories/Brands/BrandsList";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { useDelayedClose } from "../../../hooks/ui/useDelayedClose";
import { toggleInList } from "../../../utils/array";
import { BrandContainer } from "../../onboarding/steps/brandSelection/BrandSelection";
import { SettingsWindow } from "../UserSettings";
import { StyleSettingsTemplate } from "./StyleSettingsTemplate";
import s from "./WindowSettings.module.scss";
export const BrandsSettings: React.FC<SettingsWindow> = ({
  data,
  onChange,
}) => {
  const { onOpen, isOpen, data: localWindowData, onClose } = useDelayedClose();

  const state = {
    liked: {
      onChangeSelection: (val: string) =>
        onChange({ brands: toggleInList(data.brands ?? [], val) }),
      selected: data.brands,
      title: "My Brands",
    },
    disliked: {
      onChangeSelection: (val: string) =>
        onChange({
          dislikedBrands: toggleInList(data.dislikedBrands ?? [], val),
        }),
      selected: data.dislikedBrands,
      title: "Disliked Brands",
    },
  };

  const getState = (title: string) => {
    if (title === state.liked.title) return state.liked;
    return state.disliked;
  };
  return (
    <StyleSettingsTemplate
      onOpen={onOpen}
      likedItems={
        <div className={s.gridPreview}>
          {data.brands?.map((b) => (
            <BrandContainer
              selected
              onClick={() =>
                onChange({ brands: toggleInList(data.brands!, b) })
              }
            >
              {b}
            </BrandContainer>
          ))}
        </div>
      }
      likedTitle="My Brands"
      dislikedItems={
        <div className={s.gridPreview}>
          {data.dislikedBrands?.map((b) => (
            <BrandContainer
              selected
              onClick={() =>
                onChange({ brands: toggleInList(data.dislikedBrands ?? [], b) })
              }
            >
              {b}
            </BrandContainer>
          ))}
        </div>
      }
      dislikedTitle="Disliked Brands"
      selectionOptionsElement={
        <SlidingWindow
          isOpen={isOpen}
          onClose={onClose}
          title={getState(localWindowData?.name)?.title}
        >
          <BrandsList
            onSelect={getState(localWindowData?.name)?.onChangeSelection}
            onClose={onClose}
            selectedIds={getState(localWindowData?.name)?.selected ?? []}
          />
        </SlidingWindow>
      }
    />
  );
};
