import { AppTextField } from "../../../../components/Input/TextField/TextField";
import { Pill } from "../../../../components/pill/Pill";

import s from "../../common/form.module.scss";

export const UserInfoForm: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ data: profile, onChange }) => {
  const onChangeForm = (key: string, value: string) => {
    onChange({ ...profile, [key]: value });
  };
  return (
    <>
      <div className={s.form}>
        <div className={s.field}>
          <h4>Name</h4>
          <AppTextField
            placeholder="Insert name..."
            value={profile.name}
            containerClassName={s.onboardingInput}
            onChange={(ev) => onChangeForm("name", ev.target.value)}
          />
        </div>
        <div className={s.field}>
          <h4>Age</h4>
          <div className="alignCenter gapMd scrollAuto">
            {["U18", "18-24", "25-34", "35-44", "45-54", "55+"].map((d) => (
              <Pill
                size="sm"
                isSelected={profile?.age === d}
                onClick={() => onChangeForm("age", d)}
              >
                {d}
              </Pill>
            ))}
          </div>
          {/* <AppTextField
            placeholder="Insert age..."
            value={profile.age}
            containerClassName={s.onboardingInput}
            type="number"
            onChange={(ev) => onChangeForm("age", ev.target.value)}
          /> */}
        </div>
        <div className={s.field}>
          <h4>Location</h4>
          <AppTextField
            placeholder="City,Country"
            value={profile.location}
            containerClassName={s.onboardingInput}
            onChange={(ev) => onChangeForm("location", ev.target.value)}
          />
        </div>
      </div>
    </>
  );
};
