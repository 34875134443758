import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { UserInfoForm } from "./UserInfoForm";

export const UserInfoStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={UserInfoForm}
      initialData={props.preloadedData.info ?? { name: "", age: undefined }}
      title={"Let's get some basic info about you..."}
      onSkip={props.onSkip}
      onSubmit={(data) => props.onNextStep({ info: data })}
      validate={(data) => !!data.age && !!data.name}
    />
  );
};
