import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { ColorPreferencesInfo } from "./ColorPreferencesInfo";

export const ColorsStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      onSubmit={(data: any) => props.onNextStep({ colors: data })}
      onSkip={props.onSkip}
      Component={ColorPreferencesInfo}
      initialData={props.preloadedData.colors}
      title="How do you feel about incorporating color into your wardrobe?"
      validate={() => true}
    />
  );
};
