import { useState } from "react";
import { Loader } from "../../../../components/loader/Loader";
import { useSnackbar } from "../../../../contexts/snackbar";
import { useOnboardStyles } from "../../../../hooks/query/onboard/useOnboardStyles";
import "./StyleSelectionInfo.css";
import TinderCard from "./Tinder";

export const StyleSelectionInfo: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ onChange, data }) => {
  const { openSnackbar } = useSnackbar();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: cards, isLoading } = useOnboardStyles(data);
  const [finished, setFinished] = useState(false);

  const handleSwipe = (type: string, rate: number) => {
    onChange({ ...data, [type]: rate });
  };

  if (isLoading) return <Loader text="Loading styles..." />;
  return (
    <div style={{ position: "relative" }}>
      {cards && (
        <TinderCard
          cards={cards as any[]}
          onSwipe={(direction, card) => {
            const mapping: Record<string, number> = {
              left: -1,
              up: 2,
              right: 1,
            };
            handleSwipe(card.key, mapping[direction] as number);
          }}
        />
      )}
    </div>
  );

  // return (
  //   <div className="style-selection-info">
  //     <div className="style-card">
  //       <div>
  //         {finished ? (
  //           <h4>Finished!</h4>
  //         ) : (
  //           <ImageCollage
  //             imgs={currentCard.srcs.map((img, index) => ({ src: img }))}
  //             count={6}
  //           />
  //         )}
  //       </div>
  //       <div className="card-overlay">
  //         <h3>{currentCard.title}</h3>
  //         <p>{currentCard.description}</p>
  //       </div>
  //     </div>

  //     <div className="action-buttons">
  //       <button onClick={() => handleSwipe(currentCard.key, -1)}>
  //         Dislike
  //       </button>
  //       <button onClick={() => handleSwipe(currentCard.key, 2)}>
  //         Super Like
  //       </button>
  //       <button onClick={() => handleSwipe(currentCard.key, 1)}>Like</button>
  //     </div>
  //   </div>
  // );
};

export default StyleSelectionInfo;
