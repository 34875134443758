import { get } from "lodash";
import { Pill } from "../../../../components/pill/Pill";
import { toggleInList } from "../../../../utils/array";

const values = [
  {
    title: "Bold & Vibrant",
    description: "Bright, colorful outfits that make a statement.",
    value: "bold",
  },
  {
    title: "Subtle and Minimalistic",
    description: "Neutral tones that are timeless and understated",
    value: "subtle",
  },
  {
    title: "Balanced and Versatile",
    description: "Neutrals with pops of color to keep it interesting",
    value: "balanced",
  },
  {
    title: "Mood-Based",
    description: "It depends on the season and what's trendy",
    value: "mood",
  },
];

export const ColorPreferencesInfo: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ data = {}, onChange }) => {
  const onChangeForm = (value: string) => {
    onChange({
      ...data,
      colorGroupPreference: toggleInList(
        data?.colorGroupPreference ?? [],
        value
      ),
    });
  };

  return (
    <div className="column gapXlg">
      {values.map((v) => {
        const isSelected = get(data, "colorGroupPreference", []).includes(
          v.value
        );
        return (
          <Pill isSelected={isSelected} onClick={() => onChangeForm(v.value)}>
            <div className="column gapMd alignCenter flex1">
              <h4>{v.title}</h4>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 300,
                  fontStyle: "italic",
                  color: isSelected ? "white" : "#737373",
                }}
              >
                {v.description}
              </span>
            </div>
          </Pill>
        );
      })}
    </div>
  );
};
