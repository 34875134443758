import React, { useEffect, useState } from "react";

interface ImageWithFallbackProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
}

export const Image: React.FC<ImageWithFallbackProps> = ({
  src,
  fallback = "",
  alt,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    if (src?.includes("hellomolly")) console.log("fallback", fallback);

    setImgSrc(fallback);
  };

  return <img {...props} src={imgSrc} alt={alt} onError={handleError} />;
};
