import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { SignUpWrapper } from "./SignUpWrapper";

export const SignupStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={SignUpWrapper}
      validate={() => true}
      onSubmit={async (data) => {
        await props.onNextStep({ signInDetails: data });
      }}
      awaitResponse
      error={props.error}
      onSkip={props.onSkip}
      initialData={props.preloadedData.signInDetails}
      title="Create an Account"
    />
  );
};
