import { Heart, Star, X } from "lucide-react";
import { useState } from "react";
import { ImageCollage } from "../../../../components/imageCollage/ImageCollage";

import "./StyleSelectionInfo";

// import { useSpring, animated } from "@react-spring/web";
// import { useDrag } from "@use-gesture/react";
// import { useState } from "react";

interface TinderCardProps {
  cards: {
    key: string;
    urls: string[];
    description: string;
    title: string;
  }[];
  onSwipe: (direction: "left" | "right" | "up", card: any) => void;
}

const TinderCard = ({ cards, onSwipe }: TinderCardProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [indicator, setIndicator] = useState<"left" | "right" | "up" | null>(
    null
  );

  // const [{ x, y, rotate }, api] = useSpring(() => ({
  //   x: 0,
  //   y: 0,
  //   rotate: 0,
  //   config: { tension: 300, friction: 20 },
  // }));

  const currentCard = activeIndex < cards.length ? cards[activeIndex] : null;
  const nextCard = cards[activeIndex + 1];

  const handleSwipe = (direction: "left" | "right" | "up") => {
    onSwipe(direction, currentCard);
    setActiveIndex((prev) => prev + 1);
    // api.start({
    //   x: direction === "left" ? -500 : direction === "right" ? 500 : 0,
    //   y: direction === "up" ? -500 : 0,
    //   rotate: direction === "left" ? -20 : direction === "right" ? 20 : 0,
    //   onRest: () => {

    //     api.start({ x: 0, y: 0, rotate: 0, immediate: true });
    //     setIndicator(null);
    //   },
    // });
  };
  console.log(cards);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        aspectRatio: "4/5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Card Stack */}
      <div className="card-stack">
        {cards.slice(activeIndex).map((c, index) => (
          <div
            // {...bind()}
            // style={{
            //   x,
            //   y,
            //   // rotate,
            //   // touchAction: "none",
            //   // cursor: "grab",
            // }}
            style={{ zIndex: 100 - index }}
            className="card-container"
          >
            <div className="style-selection-info">
              <div className="style-card">
                <ImageCollage
                  imgs={c.urls.map((img, index) => ({ src: img }))}
                  count={6}
                />
              </div>
              <div className="card-overlay">
                <h3>{c.title}</h3>
                <p>{c.description}</p>
              </div>
            </div>
          </div>
        ))}

        {!currentCard && <h2>Great job! let's move on the the next step</h2>}

        {/* Swipe Indicators */}
      </div>

      {/* Action Buttons */}
      {currentCard && (
        <div className="action-buttons">
          <div onClick={() => handleSwipe("left")}>
            <X />
          </div>
          <div onClick={() => handleSwipe("up")}>
            <Star />
          </div>
          <div onClick={() => handleSwipe("right")}>
            <Heart />
          </div>
        </div>
      )}
    </div>
  );
};

export default TinderCard;
