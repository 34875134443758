import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { BodyInfo } from "./BodyInfo";

export const BodyStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={BodyInfo}
      initialData={props.preloadedData.body}
      onSubmit={(data) => props.onNextStep({ body: data })}
      onSkip={props.onSkip}
      validate={() => true}
      title="What are your build preferences?"
    />
  );
};
