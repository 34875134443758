import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { CommonFilter } from "../../../@types/entities/product.types";
import {
  getWizardBrands,
  getWizardSuggestedBrands,
} from "../../../services/api/users/wizard/wizard.api";
import { useAppSelector } from "../../../store";

export const useProductFeatureFilters = () => {
  const { filterOptions } = useAppSelector((store) => store.app);
  const filtersMeta = [
    {
      key: "price",
      label: "Price",
      value: [10, 1000],
    },
    {
      key: "brand",
      label: "Brand",
      value: [],
    },
  ] as unknown as CommonFilter[];

  if (!filterOptions.isLoading && isEmpty(filterOptions.values)) {
    filtersMeta.push({
      key: "category",
      label: "Category",
      value: ["top", "skirt", "pants", "shorts", "dress", "coats"],
    });
  }

  return {
    data: {
      metadata: {
        filters: filtersMeta,
      },
      features: {
        filters: filterOptions.values.filter((f) => f.key !== "category"),
      },
      category: filterOptions.values.find((f) => f.key === "category")
        ?.value as unknown as string,
    },
    isLoading: filterOptions.isLoading,
    isError: false,
  };
};

export const useWizardBrands = (term = "") =>
  useQuery({
    queryFn: async () => {
      const res = await getWizardBrands(term);
      return res.items;
    },
    enabled: term?.length > 1 || !term?.length,
    queryKey: ["WizardBrands", term],
    keepPreviousData: true,
  });
export const useWizardSuggestedBrands = (id?: string) =>
  useQuery({
    queryFn: async () => {
      const res = await getWizardSuggestedBrands({ id: id!, limit: 15 });
      return res.items;
    },
    // keepPreviousData: true,
    queryKey: ["WizardBrandsSuggestion", id],
    enabled: !!id,
  });
