import { Pill } from "../../../../components/pill/Pill";
import { TypingDisplay } from "../../../../components/typingDisplay/TypingDisplay";
import { useChat } from "../../../../hooks/useChat";
import { ReactComponent as Stars } from "../../../../styles/icons/ai.svg";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";
import { GradientBackground } from "../../HomePage";

export const GigiPreview = () => {
  const { onChatWithEngine, onChat } = useChat();
  return (
    <GradientBackground>
      <div
        style={{
          overflow: "hidden",
          maxWidth: "100vw",
          minWidth: "100vw",

          padding: "32px 16px",
        }}
        className="column gapMd"
      >
        <div className="column gapLg flex1 alignCenter">
          <h2>Gigi's Recs</h2>
          <div style={{ minHeight: "60px" }} className="alignStart gapMd flex">
            <div
              style={{
                minHeight: "40px",
                maxHeight: "40px",
                minWidth: "40px",
                maxWidth: "40px",
              }}
            >
              <Gigi width={40} height={40} />
            </div>
            <p className="subTitle">
              <TypingDisplay text="Here are some suggestions for you based on your preferences" />
            </p>
          </div>
        </div>

        <div className="column gapMd">
          {/* <div
            style={{
              fontSize: 13,
              fontWeight: 600,
              paddingInlineStart: "16px",
              color: "#2d2d2d",
              textAlign: "center",
            }}
          >
            Kickstart your chat
          </div> */}
          <div className="alignCenter flex1 ">
            <div className=" column gapMd scrollAuto">
              {[
                // First row
                [
                  "comfortable day dress",
                  "edgy black dress for a party",
                  "dramatic dress for a gala",
                  "vintage-inspired floral dress",
                ],
                [
                  "glamorous sequined dress",
                  "minimalist linen dress for",
                  "romantic off-shoulder dress with lace",
                  "casual satin dress for an event",
                ],
              ].map((row, i) => (
                <div key={i} className="alignCenter flex gapMd">
                  {row.map((p) => (
                    <Pill size="sm" onClick={() => onChatWithEngine(p)}>
                      <div className="icon withFill dark">
                        <Stars width={20} height={20} />
                      </div>
                      {p}
                    </Pill>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </GradientBackground>
  );
};
