import { useState } from "react";

type UseDelayedClose = <T extends any = any>() => {
  data: T | null;
  onClose: () => void;
  onOpen: (data: T) => void;
  isOpen: boolean;
};

export const useDelayedClose: UseDelayedClose = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  return {
    onOpen(data) {
      setData(data);
      setIsOpen(true);
    },
    onClose() {
      setIsOpen(false);
      setTimeout(() => {
        setData(null);
      }, 400);
    },
    data,
    isOpen,
  };
};

export const useDelayedFunc = (timeout: number) => {
  const [isClosing, setIsClosing] = useState(false);
  const onClose = (cb: Function) => {
    setIsClosing(true);
    setTimeout(() => {
      cb();
    }, timeout);
  };

  return [isClosing, onClose] as const;
};
