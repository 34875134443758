import { noop } from "lodash";
import { FC, createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import "swiper/css/bundle";
import "swiper/swiper-bundle.css";
import { default as s, default as styles } from "./App.module.scss";
import chatStyles from "./components/chatBtn/ChatBtn.module.scss";
import { Footer } from "./components/footer/footer.mobile";
import {
  DesktopMedia,
  TouchScreenMedia,
} from "./components/media/MediaQueries";
import { SlidingWindow } from "./components/slidingWindow/SlidingWindow";
import { AppWindowPortal } from "./components/slidingWindow/types/app/AppWindowPortal";
import { ProductWindowManager } from "./components/slidingWindow/types/product/ProductWindowManager";
import { SnackbarProvider } from "./contexts/snackbar";
import { useAppInit } from "./hooks/query/useAppInit";
import { ChatPage } from "./pages/chat/ChatPage";
import { ChatPageTitle } from "./pages/chat/Cmps/ChatPageTitle/ChatPageTitle";
import { WithPathResolver } from "./pages/pathResolver/PathResolver";
import { SPECIAL_PAGES } from "./params";
import { SocketWrapper } from "./services/sockets/socket";
import { useAppSelector } from "./store";
import { appActions } from "./store/app/slice";
import AppLoaderImg from "./styles/icons/AppPreviewLoader.svg";
import "./styles/index.scss";
export const DEFAULT_USER = "65f5ae763080ef320710933f";

interface AppContextType {
  socket: React.MutableRefObject<Socket> | React.MutableRefObject<null>;
  onChat: () => void;
  isApp: boolean;
  isMobile: boolean;
  onGoToVendor: (url: string) => void;
  uploadImage: () => void;
  paddings: {
    top: number;
    bottom: number;
  };
}

export const AppContext = createContext<AppContextType>({
  onChat: noop,
  isApp: false,
  isMobile: false,
  onGoToVendor: noop,
  uploadImage: noop,
  socket: { current: null },
  paddings: {
    top: 0,
    bottom: 0,
  },
});
const App: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, didLoadHome, deviceId } = useAppSelector((store) => store.app);
  const location = useLocation();
  const init = useAppInit(user);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const appRef = useRef<HTMLDivElement>(null);
  const lastScroll = useRef(0);
  const scrollPrevDirection = useRef<"up" | "bottom" | null>(null);
  const views = useRef({ app: false, mobile: false });
  const socket = useRef<Socket | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    views.current = {
      app:
        searchParams.get("source") === "pwa" ||
        !!window.matchMedia("(display-mode: standalone)").matches,
      mobile: searchParams.get("app") === "true",
    };

    const deviceId = searchParams.get("deviceId");
    dispatch(appActions.setDeviceId(deviceId));
    socket.current = new SocketWrapper(deviceId ?? user?._id!).socket;
  }, []);

  useEffect(() => {
    if (user?._id || deviceId) {
      socket.current = new SocketWrapper(deviceId ?? user?._id!).socket;
    }
  }, [user, deviceId]);

  const onOpenChat = () => {
    setIsChatOpen(true);
    // mainContentRef.current?.classList.add(s.moveLeft);
  };
  const onCloseChat = () => {
    setIsChatOpen(false);
    // mainContentRef.current?.classList.remove(s.moveLeft);
    // remove
  };

  return (
    <AppContext.Provider
      value={{
        paddings: {
          top: views.current.mobile ? 72 : 0,
          bottom: views.current.mobile ? 42 : 0,
        },
        onChat: onOpenChat,
        isApp: views.current.app,
        isMobile: views.current.mobile,
        onGoToVendor: (url: string) => {
          if (!socket.current) return;
          socket.current.emit("goToVendor", { url });
        },
        uploadImage: () => {
          if (!socket.current) return;
          socket.current.emit("uploadImage", {});
        },
        socket: socket,
      }}
    >
      {/* <WithPathResolver include={["/login", "/", "/onboard"]}>
        <AppLoader isReady />
      </WithPathResolver> */}
      <RoutResolvedAppLoader init={!!init} />

      <SnackbarProvider>
        <>
          <DesktopMedia>
            <Outlet />
          </DesktopMedia>

          <TouchScreenMedia>
            <div
              onScroll={(ev) => {
                if (!appRef.current || !chatRef.current || !footerRef.current)
                  return;
                const currentScroll = appRef.current.scrollTop;

                if (currentScroll > lastScroll.current) {
                  if (scrollPrevDirection.current !== "bottom") {
                    scrollPrevDirection.current = "bottom";
                    // footerRef.current.style.transform = "translateY(100%)";
                    // // chatRef.current.style.padding = "14px";
                    // chatRef.current.classList.add(chatStyles.mini);
                    // chatRef.current.style.transform = `translateY(100%)`;
                  }
                } else {
                  if (scrollPrevDirection.current !== "up") {
                    chatRef.current.classList.remove(chatStyles.mini);
                    scrollPrevDirection.current = "up";
                    // chatRef.current.style.padding = "14px 20px";
                    // footerRef.current.style.transform = "translateY(0)";
                    // chatRef.current.style.transform = `translateY(0)`;
                  }
                }

                lastScroll.current = currentScroll <= 0 ? 0 : currentScroll;
              }}
              ref={appRef}
              className={` ${styles.app} `}
            >
              <div className="main-container">
                <div ref={mainContentRef} className={`main-content`}>
                  <Outlet />
                </div>
              </div>
              <ProductWindowManager
                onOpenFirst={
                  () => {}
                  // mainContentRef.current?.classList.add(s.moveLeft)
                }
                onCloseFirst={
                  () => {}
                  // mainContentRef.current?.classList.remove(s.moveLeft)
                }
              />
              <WithPathResolver exclude={SPECIAL_PAGES}>
                <Footer ref={footerRef} />
              </WithPathResolver>
            </div>
            <AppWindowPortal />
            {/* <WithPathResolver exclude={["/", "/feed"]}>
              <ChatBtn onClick={() => setIsChatOpen(true)} ref={chatRef} />
            </WithPathResolver> */}
            <SlidingWindow
              // keepMounted={false}
              // height="100%"
              title={<ChatPageTitle />}
              isOpen={isChatOpen}
              isCenteredTitle={false}
              onClose={onCloseChat}
              direction="right"
            >
              <ChatPage id="app" isActive={isChatOpen} onBack={onCloseChat} />
            </SlidingWindow>
          </TouchScreenMedia>
        </>
      </SnackbarProvider>
    </AppContext.Provider>
  );
};

export const AppLoader: React.FC<{ isReady: boolean; autoDelay?: boolean }> = ({
  isReady,
  autoDelay,
}) => {
  const [className, setClassName] = useState("");

  useEffect(() => {
    if (isReady) {
      if (autoDelay) {
        setTimeout(() => {
          setClassName(s.disappear);
        }, 1000);
      } else {
        setClassName(s.disappear);
      }
      setTimeout(() => {
        setClassName(s.none);
      }, 1500);
    }
  }, [isReady]);
  return (
    <div className={`${s.appLoader} ${className}`}>
      <img
        src={AppLoaderImg}
        style={{ objectFit: "cover", minWidth: "100vw", minHeight: "100vh" }}
      />
    </div>
  );
};
export default App;

const RoutResolvedAppLoader: React.FC<{ init: boolean }> = ({ init }) => {
  return (
    <>
      <WithPathResolver exclude={SPECIAL_PAGES}>
        <AppLoader isReady={!!init} />
      </WithPathResolver>
      <WithPathResolver include={SPECIAL_PAGES}>
        <AppLoader isReady autoDelay />
      </WithPathResolver>
    </>
  );
};
