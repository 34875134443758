import React, { useContext, useState } from "react";
import s from "./ChatInput.module.scss";

import { noop } from "lodash";
import { ChatMsg } from "../../../../@types/entities/chat";
import { AppContext } from "../../../../App";
import { Input } from "../../../../components/Input/Input";
import { Button } from "../../../../components/button/Button";
import { Pill } from "../../../../components/pill/Pill";
import { useChatOptions } from "../../../../hooks/query/chat/useChatOptions";
import { SendIcon } from "../../../../styles/icons/Send/Send";

export interface ChatInputProps {
  onSend: (x: string) => void;
  messages?: ChatMsg[];
}
export const ChatInput: React.FC<ChatInputProps> = ({ onSend, messages }) => {
  const [msg, setMsg] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { isApp, isMobile } = useContext(AppContext);
  const { options } = useChatOptions(messages);

  return (
    <div
      className={`${s.input} ${isFocused ? s.focused : ""} ${
        isApp || isMobile ? s.app : ""
      }`}
    >
      {options && (
        <div className={s.options}>
          {options.map((o, idx) => {
            return (
              <div
                className={`${s.optionLabel} no-swiping`}
                style={{ animationDelay: `${idx * 0.2}s` }}
              >
                <Pill
                  size="sm"
                  type="secondary"
                  onClick={() => {
                    onSend(o);
                  }}
                >
                  {o}
                </Pill>
              </div>
            );
          })}
        </div>
      )}
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          if (msg) {
            onSend(msg);
            setMsg("");
          }
        }}
        className="flex1 justifyCenter"
      >
        <Input
          onChange={(val) => setMsg(val)}
          placeholder={"Cute dress for an afternoon cocktail..."}
          value={msg}
          theme="light"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          containerClassName={s.chatInput}
          iconEnd={
            <Button type="tertiary" form onClick={noop}>
              <SendIcon disabled={!msg} />
            </Button>
          }
        />
      </form>
      <div className={s.footer} />
    </div>
  );
};
