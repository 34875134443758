import { useContext } from "react";
import { AppContext } from "../../App";

export const usePageStyles = () => {
  const { paddings } = useContext(AppContext);
  return {
    paddingTop: `${paddings.top}px`,
    paddingBottom: `${paddings.bottom}px`,
  };
};
