import { omit } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { User } from "../../../@types/entities/user";
import {
  createUserProfile,
  editUser,
  getPresetUsers,
  registerUser,
  setAuthForDevice,
  setCurrentUser,
} from "../../../services/api/users/users.api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { appActions } from "../../../store/app/slice";

export const useUsers = (payload?: { presets?: boolean }) =>
  useQuery({
    queryFn: async () => {
      try {
        if (payload?.presets) {
          const presets = await getPresetUsers();
          return presets.items;
        }
        return [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    queryKey: ["users", payload],
  });

export const useEditUserProfile = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { deviceId } = useAppSelector((s) => s.app);
  return useMutation({
    mutationKey: ["edit profile"],
    mutationFn: async (profile: any) => {
      const res = await registerUser(profile.signInDetails);
      if (deviceId) {
        await setAuthForDevice(deviceId as string, res._id!);
      }

      console.log("the result returned is:", res);

      await setCurrentUser(res._id!);
      const profileRes = await createUserProfile(
        omit(profile, "signInDetails") as User
      );
      console.log("The profile returned is", profileRes);
      dispatch(appActions.onSelectUser({ ...profileRes.profile, ...res }));
      return res;
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};

export const useEditUserSettings = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation({
    mutationKey: ["edit profile"],
    mutationFn: async (profile: User) => {
      const res = await editUser(profile);
      dispatch(appActions.updateUser({ ...res.profile }));
      return res;
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};
