import { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./ChatPage.module.scss";

import { ChatMsg, Chat as ChatType } from "../../@types/entities/chat";
import { generateResponseFromUserMsg } from "../../services/mock/chat/chat";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { MiniProduct } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { DesktopMedia } from "../../components/media/MediaQueries";
import { socketService } from "../../services/mock/socket";
import { Chat } from "./Cmps/Chat/Chat";
import { ChatInput } from "./Cmps/ChatInput/ChatInput";
import { ChatList } from "./Cmps/ChatList/ChatList";
import { ChatResultsPage } from "./Cmps/chatResultsPage/ChatResultsPage";

export const ChatPage: FC<{
  onBack: () => void;
  id: string;
  inWindow?: boolean;
  isActive?: boolean;
}> = ({ onBack, id, inWindow, isActive }) => {
  const [actionStyle, setActionStyle] = useState("");
  const dispatch = useDispatch();
  const [chat, setChat] = useState<ChatType>();
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [chatType, setChatType] = useState<"mock" | "gpt">("gpt");
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<MiniProduct | null>(
    null
  );
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const { isMobile } = useContext(AppContext);
  const [currentResultsMsgId, setCurrentMsgResultsId] = useState<string | null>(
    null
  );
  const historyRef = useRef<ChatMsg[]>([]);

  useEffect(() => {
    (async () => {
      socketService.on("chatMessage", id, (data: ChatMsg) => {
        historyRef.current.push(data);
        setChat((prev) => ({
          lastUpdate: Date.now(),
          messages: prev?.messages ? [...prev.messages, data] : [data],
          createdAt: Date.now(),
          title: "new chat",
          id: Date.now() + "",
        }));
      });

      socketService.on(
        "userMessage",
        id,
        async (chat: {
          data: ChatMsg;
          type: "getSimilar" | "fineTuneResult" | "matchLook" | "searchProduct";
        }) => {
          if (!isOpen) setIsOpen(true);
          console.log("in socket", chat.data);

          historyRef.current.push(chat.data);
          setChat((prev) => {
            return {
              lastUpdate: Date.now(),
              messages: prev?.messages
                ? [...prev.messages, chat.data]
                : [chat.data],
              createdAt: Date.now(),
              title: "new chat",
              id: Date.now() + "",
            };
          });

          setIsChatLoading(true);
          const msg = await generateResponseFromUserMsg(
            chat.data,
            chat.type,
            historyRef.current.slice(0, -1)
          );
          socketService.emit("chatMessage", id, {
            sender: "chat",
            timestamp: Date.now(),
            ...msg,
          });
          setIsChatLoading(false);
        }
      );
    })();
    return () => {
      socketService.off("userMessage", id);
      socketService.off("chatMessage", id);
    };
  }, []);

  useEffect(() => {
    if (!isActive) {
      clearChat();
    }
  }, [isActive]);

  const clearChat = () => {
    setChat({
      lastUpdate: Date.now(),
      messages: [],
      createdAt: Date.now(),
      title: "new chat",
      id: Date.now() + "",
    });
    historyRef.current = [];
  };

  useEffect(() => {
    clearChat();
  }, [chatType]);

  const onClickProduct = (product: MiniProduct) => {
    if (!product) return;
    socketService.emit("windowOpen", "product", product);
  };
  // TODO: IF THIS IS NOT A HOOK BY 01.04.24, I SHOULD NEVER CODE AGAIN IN MY LIFE
  const onCloseWindow = () => {
    setIsWindowOpen(false);
    setTimeout(() => {
      setSelectedProduct(null);
    }, 300);
  };
  return (
    <>
      <div
        className={`${styles.pageContainer} ${
          isOpen
            ? styles.open
            : `${actionStyle}
            `
        }
            ${isMobile && inWindow ? styles.app : ""}

        `}
      >
        <DesktopMedia>
          <ChatList />
        </DesktopMedia>
        <div className={styles.chatContainer}>
          <div className={styles.chat}>
            <Chat
              onStartNewChat={clearChat}
              onClickProduct={(product) => {
                onClickProduct(product);
                // onBack();
              }}
              chat={chat!}
              chatId={id}
              type={chatType}
              isLoading={isChatLoading}
              goToResults={(msgId) => {
                setCurrentMsgResultsId(msgId);
              }}
            />
            <ChatInput
              messages={chat?.messages}
              onSend={(value) => {
                socketService.emit("userMessage", id, {
                  data: {
                    sender: "user",
                    content: value,
                    timestamp: Date.now(),
                  },
                  type: chatType === "mock" ? "searchProduct" : "gpt",
                });
              }}
            />
          </div>
        </div>
      </div>

      <ChatResultsPage
        msgId={currentResultsMsgId}
        onClose={() => setCurrentMsgResultsId(null)}
      />
    </>
  );
};
