import { Dictionary } from "@reduxjs/toolkit";
import { capitalize, isArray, isEmpty, keyBy } from "lodash";
import React, { useState } from "react";
import { FilterUnit } from "../../../@types/entities/product.types";
import { DropdownPill } from "../../pill/Pill";
import { SwipeableWindow } from "../../slidingWindow/SwipeableWindow";
import { BASIC_PRODUCT_FILTERS } from "../Filters";
import { FilterCmpMap } from "../filterCmpMap/map";
import s from "./FiltersPreview.module.scss";

type FilterPreview = FilterUnit & {
  label?: string;
  customValuePreview?: (value: any) => string;
};

export const PREVIEW_FILTERS = ["price", "category", ...BASIC_PRODUCT_FILTERS];
export const FiltersPreview: React.FC<{
  activeFilters: Dictionary<FilterUnit>;
  options: FilterPreview[];
  onChange: (key: string, value: any) => void;
  previewFilters?: string[];
  isLoading?: boolean;
}> = ({
  activeFilters,
  options,
  onChange,
  previewFilters = PREVIEW_FILTERS,
  isLoading,
}) => {
  const [windowName, setWindow] = useState<string | null>(null);

  const FilterPreview = !!windowName
    ? FilterCmpMap[windowName as keyof typeof FilterCmpMap]
    : null;
  const optionsMap = keyBy(options, "key");
  console.log({ optionsMap, windowName });

  return (
    <>
      {!isEmpty(options) &&
        previewFilters.map((filter) => {
          if (!optionsMap[filter]) return null;
          return (
            <div onClick={() => {}}>
              <DropdownPill
                size="sm"
                onClick={() => setWindow(filter)}
                isSelected={!isEmpty(activeFilters[filter]?.value)}
              >
                {
                  buildPreviewLabel(
                    optionsMap[filter],
                    activeFilters[filter]?.value
                  ) as any
                }
              </DropdownPill>
            </div>
          );
        })}
      {isLoading &&
        Array.from({ length: 4 }, (_, index) => (
          <DropdownPill
            key={index}
            isLoading={isLoading}
            size="sm"
            onClick={() => {}}
          >
            {null}
          </DropdownPill>
        ))}
      <div
        // TODO: BUG, IT IS HIDING THE FILTER BUTTONS ON CLICK. THIS IS A WORKAROUND
        style={{ display: "none" }}
      >
        <SwipeableWindow
          // title={`Select ${windowName}`}
          onClose={() => setWindow(null)}
          isOpen={!!windowName && windowName !== "allFilters"}
          height="20%"
        >
          <>
            <div className={s.titleContainer}>
              <span className={s.title}>{windowName?.replace("_", " ")}</span>

              <div
                className={s.clear}
                onClick={() => onChange(windowName!, null)}
              >
                Clear all
              </div>
            </div>
            <div className={`${s.filterPreviewWrapper} no-swiping`}>
              {FilterPreview && (
                <div className="paddingLg flex1 flex overflowHidden">
                  <FilterPreview
                    onChange={(value) => {
                      console.log("onChange", value);
                      onChange(windowName!, value);
                    }}
                    options={keyBy(options, "key")[windowName!]?.value ?? []}
                    selectedOptions={activeFilters[windowName!]?.value ?? []}
                  />
                </div>
              )}
            </div>
          </>
        </SwipeableWindow>
      </div>
    </>
  );
};

const buildPreviewLabel = (
  filter: FilterPreview,
  value?: FilterUnit["value"]
) => {
  const label = capitalize(filter.label ?? filter.key.replaceAll("_", ""));
  if (isEmpty(value)) return label;

  const resolveDefaultValue = () => {
    if (isArray(value)) {
      return (
        <div
          style={{
            color: "#1f2029",
            minWidth: "14px",
            height: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "50%",
            fontWeight: 500,
            fontSize: "10px",
          }}
        >
          {value.length}
        </div>
      );
    }
    return value;
  };

  const valuePreview = filter.customValuePreview
    ? filter.customValuePreview(value)
    : resolveDefaultValue();

  return (
    <>
      {label} {valuePreview}
    </>
  );
};
