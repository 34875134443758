import { FC, useEffect, useRef } from "react";
import { Blinker } from "../../pages/landing/landingV2";
import s from "./typingDisplay.module.scss";
export const TypingDisplay: FC<{ text: string; delay?: number }> = ({
  text,
  delay = 0, // Default delay to 0 if not provided
}) => {
  const typingRef = useRef<HTMLDivElement>(null);
  const blinker = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    let intervalId: NodeJS.Timeout | null = null;

    const startTyping = () => {
      let currentIndex = 0;

      intervalId = setInterval(() => {
        if (!typingRef.current) return;
        blinker.current?.classList.add(s.show);
        currentIndex += 4; // Add more characters per interval for faster typing
        typingRef.current.textContent = text
          .substring(0, currentIndex)
          .replaceAll(".", ".\n\n");

        if (currentIndex >= text.length) {
          blinker.current?.classList.remove(s.show);
          clearInterval(intervalId!);
        }
      }, 40);
    };
    if (delay > 0) {
      timeoutId = setTimeout(startTyping, delay);
    } else {
      startTyping();
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [text, delay]);

  return (
    <div>
      <span ref={typingRef} className={s.typingDisplay}>
        <Blinker className={s.blinker} ref={blinker} />
      </span>
    </div>
  );
};
