import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { StyleSelectionInfo } from "./StyleSelectionInfo";

export const StyleSelectionStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      onSubmit={(data) => props.onNextStep({ style: data })}
      onSkip={props.onSkip}
      validate={() => true}
      title="Select at least 3 styles you like"
      Component={StyleSelectionInfo}
      initialData={props.preloadedData.style}
    />
  );
};
