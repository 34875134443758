import { useEffect, useState } from "react";
import styles from "./useTransitionAnimation.module.scss";

interface TransitionProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  isClosing: boolean;
}

export const Transition = ({
  children,
  className = "",
  isOpen,
  isClosing,
}: TransitionProps) => (
  <div className={styles.container}>
    <div
      className={`${styles.transition} ${isOpen && styles.open} ${
        isClosing && styles.close
      } ${className}`}
    >
      {children}
    </div>
  </div>
);

interface UseTransitionAnimationProps {
  onExitComplete?: (data: any) => void;
  enterDelay?: number;
  exitDelay?: number;
}

export const useTransitionAnimation = ({
  onExitComplete,
  enterDelay = 200,
  exitDelay = 600,
}: UseTransitionAnimationProps = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(true);
    }, enterDelay);

    return () => clearTimeout(timeout);
  }, [enterDelay]);

  const startExit = (data: any) => {
    setIsClosing(true);
    const timeout = setTimeout(() => {
      onExitComplete?.(data);
    }, exitDelay);

    return () => clearTimeout(timeout);
  };

  return {
    isOpen,
    isClosing,
    startExit,
  };
};
