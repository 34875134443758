import { get, last } from "lodash";
import {
  Chat,
  ChatIntro,
  ChatMsg,
  MockInitialChat,
} from "../../../@types/entities/chat";
import { store } from "../../../store";
import { postMessage } from "../../api/chat/chat";
import {
  getMatchingItemsFor,
  getSimilarProductFor,
} from "../../api/products/MockAPIproducts";
import { allMockProducts } from "../products";
import { convertToGPTChat } from "./openAI";

export const mockChat: Chat = {
  title: "Test Chat",
  id: "2",
  createdAt: Date.now(),
  lastUpdate: Date.now(),
  messages: [
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "chat",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "chat",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "chat",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "user",
      timestamp: Date.now(),
    },
    {
      content: "Hello Jenny whats up",
      sender: "chat",
      timestamp: Date.now(),
    },
  ],
};

export const resolveMockMessages = (
  chatMsg: MockInitialChat
): Chat["messages"] => {
  switch (chatMsg.type) {
    case "blank":
      return [
        {
          sender: "chat",
          content: "Hi! how can i help you with your shopping today?",
          timestamp: Date.now(),
        },
      ];

    case "fine tune result":
      return [
        {
          sender: "user",
          content:
            "fine tune results for the search term " + chatMsg.searchTerm,

          timestamp: Date.now(),
        },
        {
          sender: "chat",
          content:
            "Sure thing,  here a common searches that fit your query. what would you like to shop for?",
          data: {
            options: [
              "slim fit stretch t shirt",
              "white t-shirt ",
              "oversize t-shirt surfer style",
            ],
          },
          timestamp: Date.now(),
        },
      ];

    default:
      return [];
  }
};

export const generateResponseFromUserMsg = async (
  msg: ChatMsg,
  type: ChatIntro,
  history: ChatMsg[]
) => {
  switch (type) {
    case "fineTuneResult":
      return await getResponseForChat(msg, history);
    case "getSimilar":
      const product = get(msg.data?.products, 0);
      if (!product)
        return { content: "Could not find product. error occurred" };
      const products = getSimilarProductFor(product, 6);
      if (!products)
        return {
          content:
            "I am sorry, i do not have similar items for this product. probably a bug.",
        };
      return {
        content: `Sure thing, here are similar products for ${product.title} based on your preferences. feel free to guide me more what you are looking for.`,
        data: {
          products,
        },
      };
    case "matchLook":
      const productt = get(msg.data?.products, 0);
      if (!productt)
        return { content: "Could not find product. error occurred" };
      const productss = getMatchingItemsFor(productt, 6);
      return {
        content:
          "Sure thing, i found the following products that match the product" +
          get(msg.data?.products, 0, { title: "" }).title,
        data: {
          products: productss,
        },
      };
    case "searchProduct":
      return await getResponseForChat(msg, history);
    case "gpt":
      return await getGPTResponse(msg, history);
  }
};

const getResponseForChat = async (msg: ChatMsg, history: ChatMsg[]) => {
  const getChatByUser = async (): Promise<{
    keywords: string[];
    messages: Omit<ChatMsg, "sender">[];
  }> => {
    const user = store.getState().app.user;
    const keywords = [];
    if (user?.tag === "avatarMaya") {
      keywords.push("wedding guest dress", "afternoon wedding", "2 straps");
      const messages = [
        {
          content:
            "Sure thing, to get you the most relevant results, i will need more information. you can describe in great detail and i will tailor the results to you. where and when is the wedding? what shades to you prefer? any desired cuttings?  ",
        },
        {
          content:
            "showing you results that can be ideal for an afternoon wedding by the beach, with a preference to Maxi dresses. you cane refine the results as you wish.",
          data: {
            products: allMockProducts
              .filter(
                (p) =>
                  p.tags?.includes("maxi") &&
                  p.tags.includes(store.getState().app.user?.tag ?? "")
              )
              .slice(0, 5),
          },
        },
        {
          content:
            "no problem.I refined the results to show you only two straps and a deeper V-shape cleavage",
          data: {
            products: allMockProducts
              .filter(
                (p) =>
                  p.tags?.includes("2 straps") &&
                  p.tags.includes(store.getState().app.user?.tag ?? "")
              )
              .slice(0, 5),
          },
        },
      ];

      return { keywords, messages };
    }
    if (user?.tag === "avatarViki") {
      keywords.push("wedding guest dress", "afternoon wedding", "sexy");
      const messages = [
        {
          content:
            "Sure thing, to get you the most relevant results, i will need more information. you can describe in great detail and i will tailor the results to you. where and when is the wedding? what shades to you prefer? any desired cuttings?  ",
        },
        {
          content:
            "showing you results that can be ideal for an afternoon wedding in the Hamptons, keeping your personal style. you can refine the results as you wish.",
          data: {
            products: allMockProducts
              .filter((p) => {
                return (
                  p.tags?.includes("afternoon wedding") &&
                  p.tags.includes(store.getState().app.user?.tag ?? "")
                );
              })
              .slice(0, 6),
          },
        },
        {
          content:
            "no problem.I refined the results to show you something sexy and daring for a an afternoon wedding in the Hamptons",
          data: {
            products: allMockProducts
              .filter(
                (p) =>
                  p.tags?.includes("daring and sexy") &&
                  p.tags.includes(store.getState().app.user?.tag ?? "")
              )
              .slice(0, 6),
          },
        },
      ];
      return { keywords, messages };
    }
    return { keywords: [""], messages: [] };
  };
  const chat = await getChatByUser();
  const currentMsgIdx = chat.keywords.findIndex((term) =>
    msg.content.includes(term)
  );
  console.log({ currentMsgIdx });

  if (currentMsgIdx !== -1) {
    return chat.messages[currentMsgIdx];
  }
  return {
    content: "oops i have a bug the index of terms is -1",
  };
};

const getGPTResponse = async (msg: ChatMsg, history: ChatMsg[]) => {
  console.log(msg);
  const referencedProductId = history.length
    ? history[0].data?.productReferenceId
    : msg.data?.productReferenceId;
  const message = await postMessage({
    history: history.flatMap(convertToGPTChat),
    content: msg.content,
    data: {
      ...(referencedProductId ? { referencedProductId } : {}),
      responseId: last(history)?.data?.responseId,
    },
    chatId: history?.find((msg) => msg.chatId)?.chatId,
  });

  return message;
};
