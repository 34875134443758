import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { get, isEmpty, keyBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FilterUnit } from "../../@types/entities/product.types";
import { useProductFeatureFilters } from "../../hooks/query/product/useProductFilters";
import { useFilterActions } from "../../hooks/system/useFilterActions";
import { useAppDispatch, useAppSelector } from "../../store";
import { appActions } from "../../store/app/slice";
import { toggleInList } from "../../utils/array";
import { Button } from "../button/Button";
import { FloatingFooter } from "../footer/floatingFooter/FloatingFooter";
import { Loader } from "../loader/Loader";
import { Pill } from "../pill/Pill";
import { SlidingWindow } from "../slidingWindow/SlidingWindow";
import styles from "./Filters.module.scss";
import { BrandsList } from "./filterCategories/Brands/BrandsList";
import { PriceRangeValues } from "./filterCmpMap/PriceRangeValues";
import { LabelValues } from "./filterCmpMap/labelValues/LabelValues";
import { FilterCmpMap } from "./filterCmpMap/map";
import { FilterValuesProps } from "./filterCmpMap/types";

export const BASIC_PRODUCT_FILTERS = [
  // "price",
  "dominant_color",
  // "brand",
  "length",
  "neckline",
  "fit",
  // "strap",
  // "skirt",
];

export interface FilterProps {
  onSubmit: () => void;
}

export const Filters: React.FC<FilterProps> = ({ onSubmit }) => {
  const { filters } = useAppSelector((store) => store.app);
  const [localFilters, setLocalFilters] = useState<FilterUnit[]>(filters);
  const dispatch = useAppDispatch();
  const {
    data: filtersOptions,
    isLoading,
    isError,
  } = useProductFeatureFilters();
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const { updateFilter } = useFilterActions();

  // const { data: productCount, isLoading: isLoadingCount } = useQuery({
  //   queryFn: async () => {
  //     const res = await getProductCount(localFilters);
  //     return res.count;
  //   },
  //   keepPreviousData: true,
  //   queryKey: ["productCountFromFilter", localFilters],
  // });

  const [localPricesRatios, setLocalPricesRatios] = useState<
    [number, number] | null
  >(null);

  useEffect(() => {
    if (filtersOptions?.metadata) {
      setLocalPricesRatios(getPriceRanges(filtersOptions.metadata.filters));
    }
  }, [filtersOptions?.metadata]);

  const adaptedFeatures = useMemo(() => {
    if (!filtersOptions) return {};
    return keyBy(
      [
        ...(filtersOptions?.features.filters ?? []),
        ...(filtersOptions?.metadata.filters ?? []),
      ],
      "key"
    );
  }, [filtersOptions]);

  const activeFilters = useMemo(() => {
    return keyBy(localFilters, "key");
  }, [localFilters]);

  const onUpdateFilter = async (key: string, value: string) => {
    const updatedFilters = updateFilter(key, value, localFilters);
    setLocalFilters(updatedFilters);
  };

  const onSubmitFilters = (filters: FilterUnit[]) => {
    dispatch(appActions.setFilters({ filters }));
    onSubmit();
  };

  const toggleSelected = (key: string) => {
    setOpenFilters((prev) => toggleInList(prev, key));
  };

  if (isLoading) return <Loader />;
  if (!filtersOptions || isError) return <div>error...</div>;
  if (isEmpty(adaptedFeatures)) return null;
  return (
    <>
      <div style={{ paddingTop: "0px" }} className="paddingLg alignCenter">
        <div className="flex1" />
        <h3>Filters</h3>
        <div className="flex1 justifyEnd">
          <Button
            type="secondary"
            onClick={() => {
              setLocalFilters([]);
              dispatch(appActions.setFilters({ filters: [] }));
            }}
          >
            clear all
          </Button>
        </div>
      </div>
      <div className="column flex1 overflowHidden">
        <div className={`${styles.filterContainer} no-swiping`}>
          <div className={styles.meta}>
            {filtersOptions.metadata.filters.map((filter) => {
              if (filter.key === "brand") {
                return (
                  <div className={styles.filterBlock}>
                    <FilterListItem
                      title="brands"
                      activeCount={activeFilters?.brand?.value?.length ?? 0}
                      onSelect={() => toggleSelected("brands")}
                    />
                  </div>
                );
              }

              if (filter.key === "price") {
                return (
                  <>
                    <div className={styles.filterBlock}>
                      <div className={styles.category}>Price</div>
                    </div>
                    <div className={styles.filterBlock}>
                      <PriceRangeValues
                        selectedOptions={activeFilters.price?.value}
                        options={adaptedFeatures.price.value}
                        onChange={(val) =>
                          setLocalFilters((prev) =>
                            updateFilter("price", val, prev)
                          )
                        }
                      />
                    </div>
                  </>
                );
              }
            })}
          </div>
          <div className={styles.filterBlock}>
            <div className={styles.category}>Types</div>
            <div style={{ marginBottom: "10px" }}>
              <LabelValues
                selectedOptions={
                  activeFilters.tags ? activeFilters.tags.value : []
                }
                options={[
                  "formal",
                  "semi-formal",
                  "casual",
                  "party",
                  "premium",
                  "fast fashion",
                  "day",
                  "night",
                ]}
                onChange={(value) => onUpdateFilter("tags", value)}
              />
            </div>
          </div>

          <div className={styles.features}>
            <div className="column flex1 gapMd">
              <div className={styles.filterBlock}>
                <div className={styles.category}>Essentials</div>
              </div>
              <div className={styles.section}>
                {BASIC_PRODUCT_FILTERS.map((key) => {
                  const Cmp = FilterCmpMap[key as keyof typeof FilterCmpMap] as
                    | React.FC<FilterValuesProps>
                    | undefined;

                  const isOpen = openFilters.includes(key);
                  return (
                    <>
                      {!isEmpty(adaptedFeatures[key]?.value) && (
                        <div className={styles.filterBlock}>
                          <div
                            className={styles.subCategory}
                            onClick={() => toggleSelected(key)}
                          >
                            {key.replaceAll("_", " ")}
                            <div className="alignCenter gapMd">
                              <Count
                                count={activeFilters[key]?.value?.length}
                              />

                              <div
                                className={`${styles.icon} ${
                                  isOpen ? styles.open : ""
                                }`}
                              >
                                <ArrowForwardIosRoundedIcon fontSize="inherit" />
                              </div>
                            </div>
                          </div>

                          <div
                            className={`${styles.values} ${
                              isOpen ? styles.open : ""
                            }`}
                          >
                            <div
                              style={{
                                marginTop: "16px",
                                marginBottom: "16px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                flex: 1,
                                overflow: "auto",
                              }}
                            >
                              {Cmp ? (
                                <Cmp
                                  selectedOptions={
                                    activeFilters[key]
                                      ? activeFilters[key].value
                                      : []
                                  }
                                  options={adaptedFeatures[key].value}
                                  onChange={(value) =>
                                    onUpdateFilter(key, value)
                                  }
                                />
                              ) : (
                                adaptedFeatures[key].value
                                  .filter((option) => !!option)
                                  .map((option) => {
                                    return (
                                      <Pill
                                        size="sm"
                                        onClick={() =>
                                          onUpdateFilter(key, option)
                                        }
                                        isSelected={
                                          activeFilters[key] &&
                                          activeFilters[key].value.includes(
                                            option
                                          )
                                        }
                                      >
                                        {option}
                                      </Pill>
                                    );
                                  })
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="column flex1 gapMd">
              <div className={styles.filterBlock}>
                <div className={styles.category}>Advanced</div>
              </div>
              <div className={styles.section}>
                {filtersOptions.features.filters
                  .filter(({ key }) => !BASIC_PRODUCT_FILTERS.includes(key))
                  .map(({ key }) => {
                    console.log(key);
                    // TODO: DUPLICATED CODE YOU PIECE OF SHIT
                    const isOpen = openFilters.includes(key);
                    return (
                      <>
                        {!isEmpty(adaptedFeatures[key]?.value) && (
                          <div className={styles.filterBlock}>
                            <div
                              className={styles.subCategory}
                              onClick={() => toggleSelected(key)}
                            >
                              {key.replace("_", " ")}
                              <div className="alignCenter gapMd">
                                <Count
                                  count={activeFilters[key]?.value?.length}
                                />

                                <div
                                  className={`${styles.icon} ${
                                    isOpen ? styles.open : ""
                                  }`}
                                >
                                  <ArrowForwardIosRoundedIcon fontSize="inherit" />
                                </div>
                              </div>
                            </div>

                            <div
                              className={`${styles.values} ${
                                isOpen ? styles.open : ""
                              }`}
                            >
                              <div
                                style={{
                                  marginTop: "16px",
                                  marginBottom: "16px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "16px",
                                }}
                              >
                                {(
                                  get(
                                    adaptedFeatures,
                                    `${key}.value`,
                                    []
                                  ) as any[]
                                )
                                  .filter((option: any) => !!option)
                                  .map((option: any) => (
                                    <Pill
                                      size="sm"
                                      onClick={() =>
                                        onUpdateFilter(key, option)
                                      }
                                      isSelected={
                                        activeFilters[key]?.value?.includes(
                                          option
                                        ) ?? false
                                      }
                                    >
                                      {option}
                                    </Pill>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <FloatingFooter>
          <Button
            size="lg"
            onClick={() => onSubmitFilters(localFilters)}
            type="primary"
          >
            Apply filters
          </Button>
        </FloatingFooter>

        <SlidingWindow
          isOpen={openFilters.includes("brands")}
          onClose={() => toggleSelected("brands")}
          direction="bottom"
          height="100%"
          title="Select Brands"
        >
          <BrandsList
            onClose={() => toggleSelected("brands")}
            selectedIds={activeFilters?.brand_code?.value ?? []}
            onSelect={(value) => onUpdateFilter("brand_code", value)}
          />
        </SlidingWindow>
      </div>
    </>
  );
};

function getPriceRanges(filters: FilterUnit[]): [number, number] {
  const prices = filters.find(({ key }) => key === "price");
  if (!prices) return [0, 0];
  return [
    Math.min(...(prices.value as unknown as number[])),
    Math.max(...(prices.value as unknown as number[])),
  ];
}

export const FilterListItem: React.FC<{
  title: string;
  onSelect: () => void;
  activeCount: number;
  isOpen?: boolean;
}> = ({ isOpen, activeCount, onSelect, title }) => {
  return (
    <div className={styles.subCategory} onClick={onSelect}>
      {title}
      <div className="alignCenter gapMd">
        <Count count={activeCount} />
        <div className={`${styles.icon} ${isOpen ? styles.open : ""}`}>
          <ArrowForwardIosRoundedIcon fontSize="inherit" />
        </div>
      </div>
    </div>
  );
};

export const Count: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div className={`${styles.count} ${count ? styles.show : ""}`}>
      {count || ""}
    </div>
  );
};
