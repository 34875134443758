import { capitalize } from "lodash";
import { useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useAppSelector } from "../../../../store";
import { FeedV2 } from "../../../feed/FeedV2";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import s from "./HomeView.module.scss";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";
export const PersonalFeedPreview: React.FC<{
  products: MiniProduct[];
  onOpenView: () => void;
  onClickProduct: (p: MiniProduct) => void;
}> = ({ products, onClickProduct, onOpenView }) => {
  const [windowName, setWindow] = useState<string>("");
  const { user } = useAppSelector((s) => s.app);
  if (!user) return null;
  return (
    <>
      <ViewPreviewTemplate
        cta="See more"
        description="Gigi recommends items in real-time based on your shopping behavior"
        onClickCta={onOpenView}
        title={`${capitalize(user.name ?? user!.info?.name)}'s Feed`}
      >
        <div className={s.feedContentScroller}>
          {products.slice(0, 9).map((p, index) => (
            <ProductCard hideLike product={p} onClick={onClickProduct} />
          ))}
        </div>
      </ViewPreviewTemplate>

      <SlidingWindow
        isOpen={!!windowName}
        onClose={() => setWindow("")}
        title={"My Personalized Feed"}
        direction="right"
        keepMounted={false}
      >
        <FeedV2 />
      </SlidingWindow>
    </>
  );
};
