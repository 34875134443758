import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { useBrands } from "../../../../hooks/query/brands/useBrands";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { toggleInList } from "../../../../utils/array";
import { LargeListValues } from "../../filterCmpMap/largeListValues/LargeListValues";

export const BrandsList: React.FC<{
  selectedIds: string[];
  onSelect: (id: string) => void;
  onClose: () => void;
}> = ({ onSelect, selectedIds, onClose }) => {
  const [filters, setFilters] = useState<{ tags: string[]; term: string }>({
    tags: [],
    term: "",
  });
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useBrands(filters);
  const flattened = useFlattenInfiniteQuery(data);

  const debounced = useCallback(
    debounce((value) => {
      setFilters((prev) => ({ ...prev, term: value }));
    }, 200),
    []
  );

  return (
    <LargeListValues
      onFinish={() => onClose()}
      list={flattened?.map((b) => ({ id: b.code, label: b.name })) ?? []}
      onSelectItem={onSelect}
      selectedIds={selectedIds}
      placeholder="Search brands..."
      isLoading={isLoading}
      onSearch={debounced}
      tags={["fast-fashion", "premium", "luxury", "boutique"]}
      selectedTags={filters.tags}
      hasNext={hasNextPage}
      fetchNext={fetchNextPage}
      isFetchingNext={isFetchingNextPage}
      onSelectTag={(t) => {
        setFilters((prev) => ({
          ...prev,
          tags: toggleInList(prev.tags, t),
        }));
      }}
    />
  );
};
