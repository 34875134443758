import { OnboardingStep } from "../../types";
import { IntroFlow } from "./introFlow";

export const IntroStep: React.FC<OnboardingStep> = ({
  onNextStep,
  onSkip,
  preloadedData,
}) => {
  return <IntroFlow onNextStep={onNextStep} onSkip={onSkip} />;
};
