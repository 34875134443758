import { BrandSuggestion } from "../../../../@types/entities/brand";
import { Carousel } from "../../../../components/carousels/Carousel";
import { ImageCollage } from "../../../../components/imageCollage/ImageCollage";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { ViewPreviewTemplate } from "./previewTemaplate/PreviewTemplate";

export const SuggestedBrands: React.FC<{
  brands: BrandSuggestion[];
  onOpenView: () => void;
}> = ({ brands, onOpenView }) => {
  // const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteProductSearch(
      { filters: [{ key: "brand_code", value: windowData?.data }], limit: 25 },
      !!windowData?.data
    );
  const products = useFlattenInfiniteQuery(data);
  return (
    <>
      <ViewPreviewTemplate
        title="Brands For You"
        cta="See more brands"
        onClickCta={onOpenView}
      >
        <Carousel
          settings={{ enablePagination: true }}
          onClickItem={(id) => onOpen({ name: id, data: id })}
          items={brands.slice(0, 4).map((b) => ({
            title: b.name,
            id: b._id,
            element: (
              <div className="gapMd alignCenter paddingMd">
                <BrandPreview brand={b} onOpen={onOpen} />
              </div>
            ),
          }))}
        />
      </ViewPreviewTemplate>

      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onClose()}
        title={windowData?.name}
        direction="right"
      >
        <div className="sideMargin">
          <ProductsGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={!!isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </SlidingWindow>
    </>
  );
};

export const BrandPreview: React.FC<{
  brand: BrandSuggestion;
  onOpen: (data: { name: string; data: string }) => void;
}> = ({ brand, onOpen }) => {
  return (
    <div className="column gapMd" style={{ width: "100%", height: "100%" }}>
      <div
        onClick={() => onOpen({ name: brand.name, data: brand._id })}
        className="column gapLg"
        style={{
          // width: "100%",
          // minHeight: "200px",
          position: "relative",
        }}
      >
        <ImageCollage
          count={6}
          imgs={brand.products.slice(0, 6).map((p) => ({
            src: p.imgUrls[0],
            fallback: p.parsing_path ?? p.feature_parse_path ?? p.seg_path,
          }))}
        />
      </div>
    </div>
  );
};
