import { UserSignIn } from "../../OnboardPage";

export const SignUpWrapper: React.FC<{
  onChange: (data: any) => void;
  data: any;
}> = ({ data, onChange }) => {
  console.log(data);

  return (
    <UserSignIn
      onChange={(data) => {
        onChange(data);
      }}
      form={data}
      isLogin={false}
      error={data?.error}
    />
  );
};
