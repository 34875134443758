import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { findIndex } from "lodash";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { MiniProduct } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { TextBanner } from "../../components/banner/text/TextBanner";
import { Button } from "../../components/button/Button";
import { useHeaderActions } from "../../components/header/useHeaderActions";
import { PageContainer } from "../../components/page/Page";
import { SearchHeader } from "../../components/searchHeader/SearchHeader";
import { SlidingWindowData } from "../../components/slidingWindow/SlidingWindow";
import { ProductSlidingWindow } from "../../components/slidingWindow/types/product/ProductSlidingWindow";
import { useHomePage } from "../../hooks/query/home/useHomePage";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import { socketService } from "../../services/mock/socket";
import gradientBg from "../../styles/icons/AppBackground2.svg";
import { ReactComponent as Arrow } from "../../styles/icons/ArrowRight.svg";
import { LookalikeWrapper } from "../Lookalike/LookalikeProducts";
import { FeedV2 } from "../feed/FeedV2";
import s from "./HomePage.module.scss";
import { Menu } from "./cmps/menu/Menu";
import { BrandsView } from "./views/brands/BrandsView";
import { CategoriesView } from "./views/categories/Categories";
import { CelebsView } from "./views/celebs/CelebsView";
import { CollectionsView } from "./views/collections/CollectionsView";
import { EventsView } from "./views/events/Events";
import { SuggestedBrands } from "./views/home/BrandsPreview";
import { CollectionsPreview } from "./views/home/CollectionsPreview";
import { PersonalFeedPreview } from "./views/home/FeedPreview";
import { GigiPreview } from "./views/home/GigiPreview";
import { HomeBanners } from "./views/home/HomeBanners";
import { LookbooksPreview } from "./views/home/LookbooksPreview";
import { RecentlyViewed } from "./views/home/RecentlyViewed";
import { SaleView } from "./views/sale/SaleView";

enum WindowNames {
  products = "products",
  menu = "menu",
}

export const HomePage: React.FC = () => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const [isSearch, setIsSearch] = useState(false);
  const { onSearch } = useHeaderActions();
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { data, isLoading } = useHomePage();
  const [selectedPage, setSelectedPage] = useState<Page>("HOME");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    socketService.on("navClick", "home", () => {
      containerRef.current?.scrollTo({ top: 0, behavior: "auto" });
      setSelectedPage("HOME");
    });
    return () => {
      socketService.off("navClick", "home");
    };
  }, []);

  useEffect(() => {
    if (isSearch) {
      searchRef.current?.focus();
    }
  }, [isSearch]);

  const onSearchHeader = (term: string) => {
    onSearch(term);
  };

  const onSelectPage = (p: Page) => {
    if (p !== selectedPage) {
      containerRef.current?.scrollTo({ top: 0, behavior: "auto" });
      setSelectedPage(p);
    }
  };

  return (
    <PageContainer ref={containerRef}>
      <div className="column gapMd">
        <SearchHeader
          withLogo
          onSearch={onSearch}
          onBack={() => {
            navigate("/home");
          }}
          isSearchMode={false}
          stickyBottomCmp={
            <div style={{ padding: "8px 0px", backgroundColor: "white" }}>
              <NavigationBar
                selectedPage={selectedPage}
                onSelectPage={onSelectPage}
              />
            </div>
          }
        />
        <NavigationBar
          selectedPage={selectedPage}
          onSelectPage={(p) => onSelectPage(p)}
        />
        <Views
          view={selectedPage}
          onOpenView={(view: Page) => onSelectPage(view)}
        />
      </div>
    </PageContainer>
  );
};

const Views: React.FC<{ view: Page; onOpenView: (v: Page) => void }> = ({
  view,
  onOpenView,
}) => {
  switch (view) {
    case "BRANDS":
      return <BrandsView />;
    case "INSPO":
      return <CelebsView />;
    case "COLLECTIONS":
      return <CollectionsView />;
    case "CATEGORIES":
      return <CategoriesView />;
    case "HOME":
      return <HomeView onOpenView={onOpenView} />;
    case "EVENTS":
      return <EventsView />;
    case "FEED":
      return <FeedV2 />;
    case "SALE":
      return <SaleView />;
    default:
      return <></>;
  }
};

const HomeView: React.FC<{ onOpenView: (p: Page) => void }> = ({
  onOpenView,
}) => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { data, isLoading } = useHomePage();
  const navigate = useNavigate();
  return (
    <div className="flex1 column">
      <GigiPreview />
      <HomeBanners />
      <div className={s.feed}>
        <PersonalFeedPreview
          products={data?.personalFeed.items ?? []}
          onClickProduct={(p) => {
            socketService.emit("windowOpen", "product", p);
          }}
          onOpenView={() => onOpenView("FEED")}
        />
        {/* <NewIn onOpenView={() => onOpenView("NEW")} /> */}
        <CollectionsPreview onOpenView={() => onOpenView("COLLECTIONS")} />
        <TextBanner
          bg="beige"
          cta="Shop Sale"
          onClick={() => onOpenView("SALE")}
          text="SALE!"
          description="Shop Up to 75% off"
        />
        <LookbooksPreview onOpenView={() => onOpenView("INSPO")} />
        <SuggestedBrands
          onOpenView={() => onOpenView("BRANDS")}
          brands={data?.suggestedBrands.items ?? []}
        />
        {/* CATEGORIES */}
        <RecentlyViewed
          onClickProduct={(p) => socketService.emit("windowOpen", "product", p)}
          products={data?.recentlyViewed.items}
        />
      </div>
      <ProductSlidingWindow
        product={windowData?.data}
        isOpen={windowData?.name === WindowNames.products}
        onClose={() => setWindowData(null)}
        direction="right"
      />
      <Menu
        onClose={() => setWindowData(null)}
        isOpen={windowData?.name === WindowNames.menu}
      />
    </div>
  );
};

type Page =
  | "HOME"
  | "INSPO"
  | "COLLECTIONS"
  | "BRANDS"
  | "FEED"
  | "FORMAL"
  | "OFFICE"
  | "CASUAL"
  | "EVENTS"
  | "SALE"
  | "NEW"
  | "CATEGORIES";
const PAGE_CODES: Page[] = [
  "HOME",
  "FEED",
  "BRANDS",
  "INSPO",
  "COLLECTIONS",
  "SALE",
  "NEW",
];

const NavigationBar: React.FC<{
  onSelectPage: (page: Page) => void;
  selectedPage: Page;
}> = ({ onSelectPage, selectedPage }) => {
  const activeIndex = useRef(0);
  const swiperRef = useRef<SwiperClass>();

  useEffect(() => {
    const idx = findIndex(PAGE_CODES, (p) => p === selectedPage);
    if (swiperRef.current) {
      swiperRef.current.slideTo(idx);
    }
  }, [selectedPage]);

  const handleSlideClick = (index: number) => {
    onSelectPage(PAGE_CODES[index]);
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "white", padding: "0px 16px" }}
        className="alignCenter gapLg "
      >
        <Swiper
          onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
          slidesPerView={"auto"}
          spaceBetween={12}
        >
          {PAGE_CODES.map((t, index) => (
            <SwiperSlide
              style={{
                width: "auto",
                borderBottom: "3px solid transparent",
                borderColor: t === selectedPage ? "black" : "transparent",
              }}
              onClick={() => handleSlideClick(index)}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: t === selectedPage ? "black" : "#a5a5a5",
                  marginBottom: "12px",
                }}
              >
                {t}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const EventBanner = () => {
  const [callView] = useViewCaller();
  return (
    <GradientBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE AN EVENT? <br /> LET GIGI HELP YOU OUT
        </h1>
        <div className="justifyCenter">
          <Button
            onClick={() => callView({ id: "eventWizard", data: undefined })}
          >
            Find a dress
          </Button>
        </div>
      </div>
    </GradientBackground>
  );
};

const LookalikeBanner = () => {
  const [callView] = useViewCaller();
  return (
    <GradientBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE A STYLE IN MIND? <br />
          FIND LOOKALIKE PRODUCTS
        </h1>
        <div className="flex justifyCenter alignCenter">
          <div className="relative">
            <div>
              <Button onClick={() => {}}>
                <div className=" alignCenter gapMd">
                  <CameraAltOutlinedIcon sx={{ color: "white" }} />
                  Find my style
                </div>
              </Button>
            </div>
            <LookalikeWrapper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <></>
            </LookalikeWrapper>
          </div>
        </div>
      </div>
    </GradientBackground>
  );
};

export const TrendingSearches: React.FC<{
  terms: string[];
  onClickProduct: (p: MiniProduct) => void;
}> = ({ terms, onClickProduct }) => {
  return (
    <div className={s.feedTitleContainer}>
      <div>
        <div className={s.feedTitle}>Trending Searches</div>
      </div>
      <Arrow />
    </div>
  );
};
export const GradientBackground: React.FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div className={`${s.welcomeContainer}`}>
      {children}
      <img
        src={gradientBg}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
          position: "absolute",
          objectFit: "cover",
        }}
      />
    </div>
  );
};
