import { TypingDisplay } from "../../../../components/typingDisplay/TypingDisplay";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";

export const ChatTitle: React.FC<{
  title: string;
  delay?: number;
  hideIcon?: boolean;
}> = ({ title, delay, hideIcon }) => {
  return (
    <div
      style={{
        fontSize: "22px",
        fontWeight: 300,
        alignItems: "flex-start",
        lineHeight: 1.2,
        minHeight: "calc(1.4em * 2)",
        overflow: "hidden",
      }}
      className="alignCenter gapMd"
    >
      {!hideIcon && (
        <div style={{ minWidth: "40px", minHeight: "40px" }}>
          <Gigi width={40} height={40} />
        </div>
      )}
      <div>
        <TypingDisplay text={title} delay={delay} />
      </div>
    </div>
  );
};
