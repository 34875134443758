import { useQuery } from "react-query";
import { onboardingApi } from "../../../services/api/onboard/onboard.api";

export const useOnboardStyles = (formData: any) => {
  return useQuery({
    queryKey: ["onboard-styles", formData?.profile],
    queryFn: () => onboardingApi.getStyles(formData),
    cacheTime: 500000,
  });
};
