import { keyframes } from "@mui/material";
import { get, isEmpty, truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ChatMsg, Chat as ChatType } from "../../../../@types/entities/chat";
import {
  MiniProduct,
  ProductDetails,
} from "../../../../@types/entities/product.types";
import { User } from "../../../../@types/entities/user";
import { Modal } from "../../../../components/Modal/Modal";
import { Button } from "../../../../components/button/Button";
import { Loader } from "../../../../components/loader/Loader";
import {
  NotTouchScreenMedia,
  TouchScreenMedia,
} from "../../../../components/media/MediaQueries";
import { Pill } from "../../../../components/pill/Pill";
import {
  ProductSkeleton,
  StyledSkeleton,
} from "../../../../components/skeleton/ProductSkeleton";
import { socketService } from "../../../../services/mock/socket";
import { useAppSelector } from "../../../../store";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";
import { ReactComponent as NewChatIcon } from "../../../../styles/icons/newChat.svg";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import styles from "./Chat.module.scss";

interface Props {
  chat: ChatType;
  isLoading: boolean;
  goToResults: (id: string) => void;
  onClickProduct?: (product: MiniProduct) => void;
  type: "gpt" | "mock";
  chatId: string;
  onStartNewChat: () => void;
}

export const Chat: React.FC<Props> = ({
  goToResults,
  isLoading,
  chat,
  type,
  onClickProduct,
  chatId,
  onStartNewChat,
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((app) => app.app.user);
  const [debugProductModalData, setDebugProductModalData] =
    useState<ProductDetails | null>(null);

  useEffect(() => {
    if (chat?.messages.length) {
      scrollToBottom();
    }
  }, [chat?.messages]);

  const scrollToBottom = () => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTo({
        top: scrollerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onReceiveProducts = () => {
    scrollToBottom();
  };
  console.log(chat?.messages);

  return (
    <div className={styles.container} ref={scrollerRef}>
      {chat?.messages &&
        chat.messages.map((msg) => (
          <div
            className={`${styles.msgContainer} ${
              msg.sender === "user" ? styles.user : ""
            }`}
          >
            <DynamicChatContent
              msg={msg}
              onStartNewChat={onStartNewChat}
              onGoToResults={goToResults}
              onClickProductCard={
                onClickProduct
                  ? (product) => {
                      onClickProduct(product);
                    }
                  : undefined
              }
              onReceiveProducts={onReceiveProducts}
              type={type}
              chatId={chatId}
            />
          </div>
        ))}
      {isLoading && (
        <div className={`${styles.msgContainer}`}>
          <ChatResponseLoader />
        </div>
      )}
      {isEmpty(chat?.messages) && <ChatIntroMessages id={chatId} />}
      {debugProductModalData && (
        <Modal open onClose={() => setDebugProductModalData(null)}>
          <div className={styles.debugModal}>
            {get(
              debugProductModalData,
              "text_description",
              debugProductModalData.description
            )
              ?.split("\n")
              .filter((d) => !!d)
              .map((d) => (
                <>
                  {d.split(":").map((c, idx) => (
                    <span className={!idx ? styles.feature : ""}>
                      {!idx ? truncate(c, { length: 18 }) : c}
                    </span>
                  ))}
                </>
              ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

interface ChatContentProps {
  msg: ChatMsg;
  onGoToResults?: (msgId: string) => void;
  onClickProductCard?: (product: ProductDetails) => void;
  onReceiveProducts?: () => void;
  onStartNewChat?: () => void;
  chatId?: string;
}

export const ChatContent: React.FC<ChatContentProps> = ({
  msg,
  onGoToResults,
  onClickProductCard,
  onStartNewChat,
}) => {
  const [productsWithVisibleImgs, setProductsWithVisibleImgs] = useState(
    msg?.data?.products
  );

  const doesHaveContent = msg.content || msg.data?.products?.length;
  console.log({ doesHaveContent: doesHaveContent });

  const renderContentByData = () => {
    if (msg.data?.products?.length === 0) {
      return (
        <div className="column gapMd">
          I am sorry, i could not find results. I'm working on myself to get
          better every day!! feel free to start a new chat.
          {onStartNewChat && (
            <Button type="tertiary" onClick={onStartNewChat}>
              <div className="gapSm alignCenter justifyStart flex1">
                <NewChatIcon />
                Start a new chat
              </div>
            </Button>
          )}
        </div>
      );
    }
    if (msg.data?.isError) {
      return (
        <div className="column gapMd">
          {msg.content}
          {onStartNewChat && (
            <Button type="tertiary" onClick={onStartNewChat}>
              <div className="gapSm alignCenter justifyStart flex1">
                <NewChatIcon />
                Start a new chat
              </div>
            </Button>
          )}
        </div>
      );
    }
    return msg.content;
  };

  return (
    <>
      {msg.sender === "user" && doesHaveContent && (
        <div className={styles.msg}>
          {msg.data?.products?.length ? (
            <ProductCard
              product={msg.data.products[0]}
              className={styles.userProductQuery}
              disablePreviewSwipe
            />
          ) : (
            msg.content
          )}
        </div>
      )}

      {msg.sender === "chat" && (
        <div className={styles.chatSenderContainer}>
          {msg.content && (
            <div
              style={{
                gap: "12px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "flex-start",
                paddingInlineStart: "8px",
              }}
            >
              <ChatSenderThumbnail sender="chat" />
              <div className={`${styles.chatContent}`}>
                {renderContentByData()}
              </div>
            </div>
          )}

          {!!msg.data?.products?.length && (
            <>
              <div className={styles.productsMsgContainer}>
                <div className={styles.productsContainer}>
                  <TouchScreenMedia>
                    <div
                      style={{
                        overflowX: "auto",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        overflowY: "hidden",
                      }}
                    >
                      {msg.data?.products.map((product, index) => (
                        <div
                          style={{ animationDelay: `${index * 0.05}s` }}
                          className={styles.chatProductContainer}
                        >
                          <ProductCard
                            className={styles.productCard}
                            size="sm"
                            key={index}
                            product={product}
                            onClick={() =>
                              onClickProductCard && onClickProductCard(product)
                            }
                            disablePreviewSwipe
                            onFailedImg={() =>
                              setProductsWithVisibleImgs((prev) =>
                                prev?.filter((p) => p._id !== product._id)
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </TouchScreenMedia>

                  <NotTouchScreenMedia>
                    {msg.data?.products.map((product, index) => (
                      <div
                        style={{ animationDelay: `${index * 0.05}s` }}
                        className={styles.chatProductContainer}
                      >
                        <ProductCard
                          key={index}
                          product={product}
                          onClick={() =>
                            onClickProductCard && onClickProductCard(product)
                          }
                          disablePreviewSwipe
                          className={styles.chatProduct}
                        />
                      </div>
                    ))}
                  </NotTouchScreenMedia>
                </div>
                {msg.sender === "chat" && onGoToResults && (
                  <div className={styles.populateSearch}>
                    <Button
                      type="primary"
                      onClick={() =>
                        onGoToResults && onGoToResults(msg.data?.responseId!)
                      }
                    >
                      See more results
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export const ChatSenderThumbnail: React.FC<{
  sender: ChatMsg["sender"];
  user?: User;
}> = ({ user, sender }) => {
  if (sender === "user") return <></>;
  return <Gigi width={"24px"} height={"24px"} />;
};

const ChatIntroMessages: React.FC<{ id: string }> = ({ id }) => {
  return (
    <>
      {[`Hi there!💫  How can i help you shop today?`].map((msg) => (
        <div className={`${styles.msgContainer} `}>
          <ChatContent msg={{ sender: "chat", content: msg }} />
        </div>
      ))}
      <div className="flex column gapLg sideMargin">
        <div>
          <h4>Things I can help you with:</h4>
        </div>
        {[
          "Find me a cute dress for a vacation in Mykonos",
          "I want a formal yet sexy dress for an event",
          "show me some office attire dresses",
        ].map((opt) => (
          <Pill
            type="secondary"
            onClick={() => {
              socketService.emit("userMessage", id, {
                data: {
                  sender: "user",
                  content: opt,
                  timestamp: Date.now(),
                },
                type: "gpt",
              });
            }}
          >
            {opt}
          </Pill>
        ))}
      </div>
    </>
  );
};

export const DynamicChatContent: React.FC<
  ChatContentProps & {
    type: Props["type"];
    isLoading?: boolean;
  }
> = ({ type, ...props }) => {
  return (
    <>
      {type === "gpt" && !props.isLoading && <ChatContent {...props} />}
      {props.isLoading && (
        <div className={styles.msg}>
          <Loader />
        </div>
      )}
    </>
  );
};
const gradientAnimation = keyframes`
    0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 200%;
  }
`;
export const ChatResponseLoader = () => {
  return (
    <div className="gapMd flex1 paddingMd">
      <div className="column gapSm flex1">
        <div className="gapMd flex">
          <ChatSenderThumbnail sender="chat" />
          <div className="column gapSm flex1">
            <StyledSkeleton
              animation="wave"
              style={{ width: "80%", backgroundColor: "#e4e4e4" }}
            />

            <StyledSkeleton
              variant="text"
              animation="wave"
              style={{ width: "50%", backgroundColor: "#e4e4e4" }}
            />
          </div>
        </div>
        <div className="gapMd flex">
          <ProductSkeleton repeat={3} sumVersion />
        </div>
      </div>
    </div>
  );
};
