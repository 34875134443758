import { useInfiniteQuery } from "react-query";
import { LIMIT } from "../../../params";
import { getMessageProducts } from "../../../services/api/chat/chat";
import { store } from "../../../store";

export const useInfiniteMessageProductsResults = (msgId: string | null) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getMessageProducts({
        msgId: msgId!,
        limit: LIMIT,
        skip: pageParam,
      });
      return res.items;
    },
    queryKey: ["MessageProductResults", msgId],
    enabled: !!store.getState().app.user && !!msgId,
    getNextPageParam: (last, all) =>
      last.length === LIMIT ? all.length * LIMIT : null,
  });
};
